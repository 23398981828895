<template>
  <div class="main">
    <yiyu-navbar :tabTitle="tabTitle"></yiyu-navbar>
    <data-field
      v-if="form_type == 'over'"
      :dataObj="getDataObj('all', topData)"
    ></data-field>
    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
      class="relative"
      v-myLoad="isLoading"
    >
      <template #footer>
        <fixed-btn
          :btnTxt="status == 'add' ? '确认新增' : '保存'"
          @click="toSubmit"
          :loading="btnLoading"
          :disabled="btnLoading"
        ></fixed-btn>
      </template>
    </yiyu-form>
    <!-- FIXME:选择基地抄这里 -->
    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="form.mc_key"
        :curName.sync="form.mc_key_value"
        :single="true"
        title="请选择基地"
        type="2"
      ></farm-picker>
    </keep-alive>
    <radio-popup
      :showCancel="false"
      :show="addSuccessShow"
      :opts="nextOpts"
      @confirm="nextHandle"
      @cancel="toPage('back')"
      :title="(status == 'add' ? '新增' : '编辑') + '种虾成功'"
    ></radio-popup>
    <datetime-popup
      :date.sync="form.l_date"
      :show.sync="timeShow"
      title="选择接虾时间"
      @confirm="timeShow = false"
      @cancel="timeShow = false"
      type="datetime"
    >
    </datetime-popup>
    <datetime-popup
      :date.sync="form.end_date"
      :show.sync="overTimeShow"
      title="选择淘汰时间"
      @confirm="overTimeShow = false"
      @cancel="overTimeShow = false"
      type="datetime"
    >
    </datetime-popup>
    <!-- FIXME:未分类-可搜索抄这里 -->
    <tag-popup
      title="请选择"
      v-if="pickerObj.show"
      :curKeys="[pickerObj.curKey]"
      :show.sync="pickerObj.show"
      :opts="columns"
      :value-key="pickerObj.valueKey"
      :keyName="pickerObj.keyName"
      @confirmObj="confirmPicker($event, pickerObj.type)"
      @cancel="pickerObj.show = false"
      :loading="pickerObj.loading"
      :single="true"
    >
      <template #top>
        <van-search
          @search="getOpts(pickerObj.type)"
          v-model="pickerObj.kw[pickerObj.type]"
          placeholder="可搜索名称"
        ></van-search>
      </template>
      <template #empty>
        <div class="classify-tag-popup-bottom">
          <span class="btn main-c" @click="toPage('addPicker')">去添加</span>
        </div>
      </template>
    </tag-popup>
  </div>
</template>

<script>
// 接口
// 获取批次养殖池（批次跳质检）： https://www.teambition.com/project/60ffa2cbbee0dfac6d095c56/app/5eba5fba6a92214d420a3219/workspaces/60ffa2e8ac41f90046f82eed/docs/612d929541cef60001cb6579

import { numToStr } from '@/utils/utils'
import yiyuNavbar from '@/components/navbar'
import datetimePopup from '@/components/popup/datetime.vue'
import yiyuForm from '@/components/form/yiyuForm.vue'
import fixedBtn from '@/components/button/fixedBtn'
import tagPopup from '@/components/popup/tag'
import farmPicker from '@/bComponents/farmPicker.vue'
import dataField from '@/components/layout/dataField'
import radioPopup from '@/components/popup/radio'

export default {
  name: 'sBatchEdit',
  components: {
    yiyuForm,
    datetimePopup,
    yiyuNavbar,
    tagPopup,
    fixedBtn,
    farmPicker,
    radioPopup,
    dataField,
  },
  data() {
    return {
      isLoading: false,
      activated: false,
      btnLoading: false,
      status: 'add',
      form_type: 'normal', // over -- 淘汰
      thisAlias: this,
      initForm: {},
      topData: {},
      form: {
        ss_key: undefined,
        batch: undefined,
        name: undefined,
        name_value: undefined,
        alias: undefined,
        alias_value: undefined,
        strain: undefined,
        strain_value: undefined,
        l_date: undefined,
        male_surplus: undefined,
        female_surplus: undefined,
        mc_key: undefined,
        mc_key_value: undefined,
        remark: undefined,
      },
      pickerObj: {
        show: false,
        loading: false,
        valueKey: undefined,
        keyName: undefined,
        type: undefined,
        curKey: undefined,
        kw: {
          seedName: undefined,
          seedAlias: undefined,
          seedStrain: undefined,
        },
      },
      opts: {
        seedName: [],
        seedAlias: [],
        seedStrain: [],
      },
      timeShow: false,
      overTimeShow: false,
      addSuccessShow: false,
    }
  },
  computed: {
    tabTitle() {
      if (this.form_type == 'over') {
        return '种虾淘汰'
      } else {
        return '安排种虾'
      }
    },
    formTitle({ form }) {
      const { l_date, alias_value } = form
      if (l_date && alias_value) {
        const t = this.$dayjs(l_date).format('YYYY/MM/DD')
        return `${alias_value}-${t}`
      } else {
        return '种虾批次（自动生成)'
      }
    },
    formOpts() {
      let _this = this
      if (this.form_type == 'over') {
        return [
          {
            title: '',
            child: [
              {
                label: '淘汰时间',
                required: true,
                formType: 'select',
                value: this.form.end_date && this.form.end_date.slice(0, 16),
                name: 'end_date',
                click() {
                  _this.overTimeShow = true
                },
              },
              {
                formType: 'textarea',
                value: this.form.del_remark,
                name: 'del_remark',
                label: '备注',
                placeholder: '可输入淘汰原因',
              },
            ],
          },
        ]
      } else {
        return [
          {
            title: this.formTitle,
            child: [
              {
                label: '种虾名称',
                formType: 'select',
                value: this.form.name_value,
                name: 'name_value',
                click() {
                  _this.pickerObj.show = true
                  _this.pickerObj.type = 'seedName'
                  _this.pickerObj.valueKey = 'name'
                  _this.pickerObj.keyName = 'key'
                },
              },
              {
                label: '种虾别名',
                required: true,
                formType: 'input-select',
                value: this.form.alias_value,
                name: 'alias_value',
                click() {
                  _this.pickerObj.show = true
                  _this.pickerObj.type = 'seedAlias'
                  _this.pickerObj.valueKey = 'name'
                  _this.pickerObj.keyName = 'key'
                },
              },
              {
                label: '种虾品系',
                formType: 'input-select',
                value: this.form.strain_value,
                name: 'strain_value',
                click() {
                  _this.pickerObj.show = true
                  _this.pickerObj.type = 'seedStrain'
                  _this.pickerObj.valueKey = 'name'
                  _this.pickerObj.keyName = 'key'
                },
              },
              {
                label: '接虾时间',
                required: true,
                formType: 'select',
                value: this.form.l_date && this.form.l_date.slice(0, 16),
                name: 'l_date',
                click() {
                  _this.timeShow = true
                },
              },
              {
                label: '公虾数量',
                required: true,
                value: this.form.male_surplus,
                name: 'male_surplus',
                type: 'number',
              },
              {
                label: '母虾数量',
                required: true,
                value: this.form.female_surplus,
                name: 'female_surplus',
                type: 'number',
              },
              {
                label: '基地',
                formType: 'select',
                value: this.form.mc_key_value,
                name: 'mc_key_value',
                required: false,
                click() {
                  _this.$refs['farmPicker'].show = true
                },
              },
              {
                formType: 'textarea',
                value: this.form.remark,
                name: 'remark',
                label: '备注',
                placeholder: '对该种虾进行备注信息',
              },
            ],
          },
        ]
      }
    },
    columns() {
      // 避免弹出的时候未获取到数据
      return this.opts[this.pickerObj.type]
    },
    nextOpts() {
      let _this = this
      return [
        {
          key: 'continueAdd',
          value: '继续安排种虾',
          fn() {
            _this.reset()
            _this.addSuccessShow = false
          },
        },
        {
          key: 'sBatchShrimpEdit',
          value: '接虾',
          fn() {
            _this.$toPage(
              _this,
              'sBatchShrimpEdit',
              {
                status: 'add',
                ss_key: _this.form.ss_key,
              },
              {
                route_type: '$replace',
              },
            )
          },
        },
        {
          key: 'sBatchList',
          value: '返回种虾批次列表',
          fn() {
            _this.$toPage(
              _this,
              'FF_produce',
              {
                tab: 'sBatch',
              },
              {
                route_type: '$replace',
              },
            )
          },
        },
        {
          key: 'notice',
          value: '发送通知',
          fn() {
            let p = 1
            let title = '种虾安排'
            let remark = [
              {
                label: '种虾批次',
                value: _this.form.batch,
              },
              {
                label: '数量',
                value: `公${_this.form.male_surplus}；母${_this.form.female_surplus}`,
              },
              {
                label: '接虾',
                value: _this.form.l_date && _this.form.l_date.slice(0, 16),
              },
            ]
            _this.toNotice({
              p,
              title,
              remark,
              status: _this.status,
              re: [
                {
                  type: '2',
                  re_info: {
                    ss_key: _this.form.ss_key,
                  },
                },
              ],
            })
          },
        },
      ]
    },
  },
  watch: {
    // 如果母虾或公虾数量为空，则自动填充
    'form.male_surplus'() {
      if (this.status == 'add' && this.form.male_surplus) {
        this.form.female_surplus = this.form.male_surplus
      }
    },
  },
  mounted() {
    this.activated = false
    this.activatedFn()
  },
  activated() {
    if (!this.activated) {
      this.activated = true
      return
    }

    this.activatedFn()

    this.$nextTick(() => {
      if (sessionStorage['yy_back_farm_key']) {
        this.form.mc_key = sessionStorage['yy_back_farm_key']
        sessionStorage.removeItem('yy_back_farm_key')
        this.$refs.farmPicker.show = false
      }
    })

    this.$nextTick(() => {
      let key = 'yy_back_' + this.pickerObj.type
      if (sessionStorage[key]) {
        let obj = JSON.parse(sessionStorage[key])
        sessionStorage.removeItem(key)
        this.confirmPicker([obj], this.pickerObj.type)
      }
    })
  },
  methods: {
    activatedFn() {
      this.getOpts()

      if (this.$route.query) {
        let query = this.$route.query
        this.form.ss_key = query.ss_key
        this.status = query.status || 'add'
        this.form_type = query.form_type || 'normal'

        if (this.form.ss_key) {
          this.status = 'edit'
          this.getData()
        }
      }
    },
    getData() {
      this.isLoading = true
      this.$get('/settle/getShrimpOne', {
        ss_key: this.form.ss_key,
      }).then((res) => {
        this.isLoading = false
        if (res.code == 10000) {
          let data = res.data
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          this.form = data
          this.form.name_value = data.name
          this.form.name = data.name_key
          this.form.alias_value = data.alias
          this.form.alias = data.alias_key
          this.form.strain_value = data.strain
          this.form.strain = data.strain_key
          this.form.male_surplus = data.male_count
          this.form.female_surplus = data.female_count

          this.topData = {
            male_count: data.male_count,
            female_count: data.female_count,
            breed_date: data.breed_date,
            egg_count: data.egg_count,
          }
        }
      })
    },
    onSubmit() {
      this.btnLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      let url = '/settle/addShrimp'
      if (this.form_type !== 'over') {
        // 安排种虾
        form.name = form.name_value
        form.alias = form.alias_value
        form.strain = form.strain_value
      } else {
        // 淘汰种虾
        url = '/algae/weedOutShrimp'
      }
      form.batch = this.formTitle
      this.$post(url, numToStr(form)).then((res) => {
        this.btnLoading = false
        if (res.code === 10000) {
          this.form.ss_key = res.data.key
          if (this.IS_BACK) {
            sessionStorage['yy_back_ss_key'] = this.form.ss_key
            this.$go(-1, this)
          } else if (this.form_type == 'over') {
            this.$toast.success('编辑淘汰记录成功')
            this.$go(-1, this)
          } else {
            // 更新种虾别名和品系的数据
            this.$store.dispatch('getUserConfList', {
              yy_type: 'seedAlias',
              forceUpdate: true,
            })
            this.addSuccessShow = true
            if (this.form.strain_value) {
              this.$store.dispatch('getUserConfList', {
                yy_type: 'seedStrain',
                forceUpdate: true,
              })
            }
          }
        }
      })
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    getOpts(type) {
      // FIXME:获取种虾名称、别名、品系，抄这里
      let arr = ['seedName', 'seedAlias', 'seedStrain']
      if (type) arr = [type]
      arr.forEach((item) => {
        this.$store
          .dispatch('getUserConfList', {
            yy_type: item,
            kw: this.pickerObj.kw[item],
          })
          .then((res) => {
            if (res.code == 10000) {
              this.opts[item] = res.data.list
            }
          })
      })
    },
    // 重置表单
    reset() {
      this.form = this.$options.data.call(this).form
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'seedName':
          this.form.name = e[0].key
          this.form.name_value = e[0].name
          this.pickerObj.show = false
          break
        case 'seedAlias':
          this.form.alias = e[0].key
          this.form.alias_value = e[0].name
          this.pickerObj.show = false
          break
        case 'seedStrain':
          this.form.strain = e[0].key
          this.form.strain_value = e[0].name
          this.pickerObj.show = false
          break
      }
    },
    nextHandle(e) {
      if (e.obj) {
        e.obj.fn()
      } else {
        this.$toast.fail('请选择下一步操作哦')
      }
    },
    getDataObj(type, item = this.form) {
      switch (type) {
        case 'all':
          return [
            [
              {
                descText: '当前数量',
                // FIXME:当前数量抄这里
                data: `公:${item.male_count || 0},母:${item.female_count || 0}`,
              },
              {
                descText: '育苗天数',
                data: item.breed_date,
                unitText: '天',
              },
              {
                descText: '上次产卵',
                data: item.egg_count,
                unitText: '万',
              },
            ],
          ]
      }
    },
    toPage(type) {
      switch (type) {
        case 'back':
          this.$go(-1, this)
          break
        case 'addPicker':
          this.$toPage(this, 'confSet', {
            type: this.pickerObj.type,
            back: true,
          })
          break
      }
    },
  },
}
</script>
