import { render, staticRenderFns } from "./dailyFeed.vue?vue&type=template&id=2e073936&scoped=true&"
import script from "./dailyFeed.vue?vue&type=script&lang=js&"
export * from "./dailyFeed.vue?vue&type=script&lang=js&"
import style0 from "./dailyFeed.vue?vue&type=style&index=0&id=2e073936&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e073936",
  null
  
)

export default component.exports