<template>
  <div class="main">
    <yiyu-navbar tabTitle="接虾"></yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
      v-myLoad="poolLoading"
      class="relative"
    >
      <template #more>
        <handle-popver
          @del="del"
          @edit="edit"
          @notice="notice"
          :actions="actions"
        ></handle-popver>
      </template>
      <template #batchData>
        <data-field :dataObj="batchObj" type="row"></data-field>
      </template>
      <template #isPool>
        <van-collapse v-model="activeName" accordion>
          <van-checkbox
            v-model="isPool"
            shape="square"
            class="pool-handle"
            @change="changeIsPool"
            >精准到池</van-checkbox
          >
          <template v-if="isPool">
            <div class="main-c chose-ws" @click="showPicker = true">
              {{ wsKeyChosed ? '选择车间' : '请选择车间' }}
            </div>
            <div
              class="ws"
              v-for="(item, index) in wsPoolChosedList"
              :key="index"
            >
              <!-- 车间 -->
              <van-checkbox
                class="ws-checkbox"
                :name="item.ws_key"
                v-model="item.ws_checked"
                v-if="item.p_list && item.p_list.length"
                @click="checkAll(item.ws_checked, 'wsGroup' + index)"
              >
                <span class="ws-name">
                  {{ item.ws_name
                  }}{{
                    item.p_chosed && item.p_chosed.length
                      ? `(已选${item.p_chosed.length}池)`
                      : ``
                  }}
                </span>
              </van-checkbox>
              <van-checkbox-group
                :ref="'wsGroup' + index"
                v-model="item.p_chosed"
                v-show="item.p_list && item.p_list.length && isPool"
              >
                <div
                  class="warp"
                  v-for="(item1, index1) in item.p_list"
                  :key="index1"
                >
                  <van-collapse-item
                    :name="item1['p_key']"
                    :value="activeName != item1['p_key'] ? '查看详情' : ''"
                  >
                    <template #title>
                      <van-checkbox
                        :name="item1['p_key']"
                        :key="index1"
                        @click="allowPoolChoose(item1)"
                        >{{ item1.p_name || '暂无池名' }}</van-checkbox
                      >
                    </template>
                    <data-field
                      v-if="hasData(item1)"
                      :dataObj="getDataObj('allow', item1)"
                    ></data-field>
                    <van-divider
                      v-if="
                        !hasData(item1) &&
                        !item.p_chosed.includes(item1['p_key'])
                      "
                      >首次接虾，暂无历史数据</van-divider
                    >
                    <van-field
                      name="radio"
                      label="虾性别"
                      v-if="item.p_chosed.includes(item1['p_key'])"
                    >
                      <template #input>
                        <van-radio-group
                          v-model="item1.sex"
                          direction="horizontal"
                        >
                          <van-radio name="1">公</van-radio>
                          <van-radio name="2">母</van-radio>
                        </van-radio-group>
                      </template>
                    </van-field>
                    <van-field
                      label="池接虾量"
                      type="number"
                      v-model="item1.p_count"
                      placeholder="请输入池接虾量"
                      v-if="item.p_chosed.includes(item1['p_key'])"
                    >
                    </van-field>
                  </van-collapse-item>
                </div>
              </van-checkbox-group>
            </div>
          </template>
        </van-collapse>
      </template>

      <template #footer>
        <fixed-btn
          :btnTxt="status == 'add' ? '确认新增' : '保存'"
          @click="toSubmit"
          :loading="btnLoading"
          :disabled="btnLoading"
        ></fixed-btn>
      </template>
    </yiyu-form>
    <radio-popup
      :showCancel="false"
      :show="addSuccessShow"
      :opts="nextOpts"
      @confirm="nextHandle"
      @cancel="toPage('back')"
      :title="(status == 'add' ? '新增' : '编辑') + '接虾成功'"
    ></radio-popup>
    <datetime-popup
      :date.sync="form.l_date"
      :show.sync="timeShow"
      title="选择接虾时间"
      @confirm="timeShow = false"
      @cancel="timeShow = false"
      type="datetime"
    >
    </datetime-popup>
    <classify-tag-popup
      :show.sync="showPicker"
      v-if="showPicker"
      title="请选择车间"
      :list="wsPoolList"
      pLabel="typeName"
      pChild="children"
      cValue="ws_name"
      cKey="ws_key"
      @confirm="confirmPicker($event)"
      @cancel="showPicker = false"
      :single="false"
      :pCurKey.sync="wsKeyChosed"
      :loading="poolLoading"
    >
      <template #top>
        <van-search
          @search="getWsPoolData"
          v-model="keyword"
          placeholder="可搜索车间名称"
        ></van-search>
      </template>
    </classify-tag-popup>
  </div>
</template>

<script>
import classifyTagPopup from '@/components/popup/classifyTag';
import radioPopup from '@/components/popup/radio';

import { numToStr, getKeyObj } from '@/utils/utils';
import yiyuNavbar from '@/components/navbar';
import datetimePopup from '@/components/popup/datetime.vue';
import yiyuForm from '@/components/form/yiyuForm.vue';
import fixedBtn from '@/components/button/fixedBtn';
import dataField from '@/components/layout/dataField';
import handlePopver from '@/components/feedback/handlePopver';
import { Dialog } from 'vant';
export default {
  name: 'sBatchShrimpEdit',
  components: {
    yiyuForm,
    datetimePopup,
    yiyuNavbar,
    fixedBtn,
    radioPopup,
    dataField,
    handlePopver,
    classifyTagPopup,
  },
  data() {
    return {
      poolLoading: false,
      activated: false,
      btnLoading: false,
      status: 'add',
      keyword: undefined,
      thisAlias: this,
      form: {
        ss_key: undefined,
        mc_key: undefined,
        batch: undefined,
        male_count: undefined,
        female_count: undefined,
        male_p_count: undefined,
        female_p_count: undefined,
      },
      timeShow: false,
      addSuccessShow: false,
      isPool: true,
      wsPoolList: [],
      wsPoolChosedList: [], // 选中的车间
      nextKey: undefined, // 下一个待接虾key
      restTotal: 0, // 剩余接虾数
      showPicker: false,
      wsKeyChosed: [], // 选中的车间
      activeName: undefined,
    };
  },
  computed: {
    formOpts() {
      let _this = this;
      return [
        {
          title: this.form.batch || '暂无',
          slot: 'batchData',
          titleRight: 'more',
        },
        {
          title: this.form.mc_name || '暂无基地名称',
          topSlot: 'isPool',
        },
        {
          title: '',
          show: !this.isPool || (this.isPool && this.wsKeyChosed.length > 0),
          child: [
            {
              label: '公虾数量',
              value: this.form.male_count,
              type: 'number',
              name: 'male_count',
              placeholder: this.isPool
                ? '填写池信息后系统将自动填充'
                : undefined,
              readonly: this.isPool,
              change() {
                if (_this.status == 'add' && !_this.isPool) {
                  _this.form.female_count = _this.form.male_count;
                }
              },
            },
            {
              label: '母虾数量',
              value: this.form.female_count,
              type: 'number',
              name: 'female_count',
              placeholder: this.isPool
                ? '填写池信息后系统将自动填充'
                : undefined,
              readonly: this.isPool,
            },
            {
              label: '公虾池数',
              value: this.form.male_p_count,
              type: 'number',
              name: 'male_p_count',
              placeholder: this.isPool ? '选择池后系统将自动填充' : undefined,
              readonly: this.isPool,
              change() {
                if (_this.status == 'add' && !_this.isPool) {
                  _this.form.female_p_count = _this.form.male_p_count;
                }
              },
            },
            {
              label: '母虾池数',
              value: this.form.female_p_count,
              type: 'number',
              name: 'female_p_count',
              placeholder: this.isPool ? '选择池后系统将自动填充' : undefined,
              readonly: this.isPool,
            },
          ],
        },
      ];
    },
    nextOpts() {
      let _this = this;
      return [
        {
          key: 'shrimpDetail',
          value: '种虾批次详情',
          fn() {
            _this.$toPage(_this, 'sBatchDetail', {
              ss_key: _this.form.ss_key,
            });
          },
        },
        {
          key: 'continueAdd',
          value: `继续接虾(剩余${this.restTotal}批次)`,
          fn() {
            _this.continueShrimp();
          },
          show: Boolean(this.restTotal),
        },
        {
          key: 'sBatchList',
          value: '返回种虾批次列表',
          fn() {
            _this.$toPage(
              _this,
              'FF_produce',
              {
                tab: 'sBatch',
              },
              {
                route_type: '$replace',
              }
            );
          },
        },
        {
          key: 'notice',
          value: '发送通知',
          fn() {
            let p = 1;
            let title = '已接虾完成';

            let wsPoolData = [];
            this.wsPoolChosedList &&
              this.wsPoolChosedList.forEach((item) => {
                if (item.ws_checked) {
                  let obj = {
                    label: item.ws_name,
                    child: [],
                    value: undefined,
                  };

                  item.p_list &&
                    item.p_list.forEach((item1) => {
                      if (item.p_chosed.includes(item1.p_key)) {
                        obj.child.push(item1.p_name);
                        obj.value = obj.child.join('、');
                      }
                    });

                  wsPoolData.push(obj);
                }
              });
            let remark = [
              {
                label: '种虾批次',
                value: _this.form.batch,
              },
              {
                label: _this.form.mc_name, // 苗场名
                value: `公${_this.form.male_count || 0}；母${
                  _this.form.female_count || 0
                }`,
              },
              ...wsPoolData,
            ];
            _this.toNotice({
              p,
              title,
              remark,
              re: [
                {
                  type: '2',
                  re_info: {
                    ss_key: _this.form.ss_key,
                  },
                },
              ],
            });
          },
        },
      ];
    },
    batchObj() {
      return [
        [
          {
            descText: '种虾时间',
            data: this.form.l_date && this.form.l_date.slice(0, 16),
          },
          {
            descText: '计划数量',
            data: `公:${this.form.top_male_count || 0},母:${
              this.form.top_female_count || 0
            }`,
          },
          {
            descText: '养殖基地',
            data: this.form.mc_name,
          },
        ],
      ];
    },
    actions() {
      return this.FILTER_HANDLE([
        {
          text: '编辑',
          fn: 'edit',
          handle: 'SEED_BATCH_SHRIMP_EDIT',
        },
        {
          text: '删除',
          fn: 'del',
          handle: 'SEED_BATCH_SHRIMP_DEL',
        },
        {
          text: '发送通知',
          fn: 'notice',
        },
      ]);
    },
  },
  watch: {
    wsPoolChosedList: {
      deep: true,
      handler() {
        if (this.isPool) {
          let male_count = 0,
            female_count = 0,
            male_p_count = 0,
            female_p_count = 0;
          this.wsPoolChosedList.forEach((item) => {
            if (Array.isArray(item.p_list)) {
              item.p_list.forEach((item1) => {
                if (item.p_chosed && item.p_chosed.includes(item1.p_key)) {
                  if (item1.sex == 1) {
                    male_p_count++;
                    male_count += Number(item1.p_count || 0);
                  } else if (item1.sex == 2) {
                    female_p_count++;
                    female_count += Number(item1.p_count || 0);
                  }
                }
              });
            }
          });

          this.form.male_count = male_count;
          this.form.female_count = female_count;
          this.form.male_p_count = male_p_count;
          this.form.female_p_count = female_p_count;
        }
      },
    },
    // 'form.male_count'() {
    //   if(this.status == 'add' && )
    // }
  },
  mounted() {
    this.activated = false;
    if (this.$route.query) {
      let query = this.$route.query;
      this.form.ss_key = query.ss_key;
      this.status = query.status || 'add';

      if (this.form.ss_key) {
        this.getData();
      }
    }
  },
  activated() {
    if (!this.activated) {
      this.activated = true;
      return;
    }

    if (this.$route.query) {
      let query = this.$route.query;
      this.form.ss_key = query.ss_key;
      this.status = query.status || 'add';

      if (this.form.ss_key) {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      this.$get('/settle/getShrimpOne', {
        ss_key: this.form.ss_key,
      }).then((res) => {
        if (res.code == 10000) {
          let data = res.data;
          data.top_male_count = data.male_count;
          data.male_count = undefined;
          data.top_female_count = data.female_count;
          data.female_count = undefined;
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key];
          }

          this.form = data;
          if (this.form.mc_key) {
            this.getWsPoolData();
          }
        }
      });
    },
    getDataObj(type, item) {
      switch (type) {
        case 'allow':
          return [
            [
              {
                data: item.batch,
                descText: '上次批次',
              },
              {
                data: item.count,
                unitText: '万',
                descText: '上次养殖',
              },
              {
                data: item.end_date && item.end_date.slice(0, 16),
                descText: '上次完结',
              },
            ],
          ];
      }
    },
    hasData(item) {
      return item.batch || item.count || item.end_date;
    },
    getWsPoolData() {
      this.poolLoading = true;
      this.$get('/conf/getSelectConf', {
        params: {
          mc_key: this.form.mc_key,
          ws_type: 2,
          keyword: this.keyword,
        },
        select_items: ['WS'],
      }).then((res) => {
        if (res.code == 10000) {
          this.poolLoading = false;

          if (Array.isArray(res.data['WS'].value)) {
            res.data['WS'].value.forEach((item) => {
              item.ws_checked = false;
              item.p_chosed = [];

              item.breed.forEach((item1) => {
                item1.p_count = undefined;
                item1.sex = undefined;
              });
              item.p_list = [...item.breed, ...item.empty];
            });
          }

          this.wsPoolList = [
            {
              typeName: '种虾车间',
              children: res.data['WS'].value,
            },
          ];
        }
      });
    },
    onSubmit() {
      this.btnLoading = true;
      let form = JSON.parse(JSON.stringify(this.form));

      if (this.isPool) {
        form.in_pool = '1';

        let p_list = [];

        this.wsPoolChosedList.forEach((item) => {
          item.p_list &&
            item.p_list.forEach((item1) => {
              if (item.p_chosed.includes(item1.p_key)) {
                p_list.push({
                  sp_key: item1.sp_key, // "投放池key", // 修改时候需要
                  p_key: item1.p_key, // "池key",
                  sex: item1.sex, // "1",          // 1 公虾， 2 母虾
                  count: item1.p_count, // "接虾数量",
                });
              }
            });
        });

        form.p_list = p_list;
      } else {
        form.in_pool = '2';
        form.ws_keys = [];
        this.wsPoolChosedList.forEach((item) => {
          if (item.ws_checked) {
            form.ws_keys.push(item.ws_key);
          }
        });
      }

      this.$post('/settle/launch', numToStr(form)).then((res) => {
        this.btnLoading = false;
        if (res.code === 10000) {
          this.addSuccessShow = true;
          if (res.data.next) {
            this.nextKey = res.data.next.ss_key;
            this.restNum = res.data.next.total;
          }
          // 更新种虾别名和品系的数据
          this.$store.dispatch('getUserConfList', {
            yy_type: 'seedAlias',
            forceUpdate: true,
          });
          if (this.form.strain_value) {
            this.$store.dispatch('getUserConfList', {
              yy_type: 'seedStrain',
              forceUpdate: true,
            });
          }
        }
      });
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    // 继续接卸
    continueShrimp() {
      this.form = this.$options.data.call(this).form;
      this.form.ss_key = this.nextKey;
      this.getData();
    },
    confirmPicker(e) {
      console.log(e);
      this.wsKeyChosed = e.key;
      this.showPicker = false;
      let list = [];
      // 不需要另外做合并数据处理，wsPoolList会跟着wsPoolChosedList改变
      // this.wsKeyChosed.forEach((key) => {
      //   list.push(getKeyObj(key, this.wsPoolList[0]['children'], 'ws_key'))
      // })
      this.wsKeyChosed.forEach((item) => {
        list.push(getKeyObj(item, this.wsPoolList[0]['children'], 'ws_key'));
      });

      this.wsPoolChosedList = list;
    },
    nextHandle(e) {
      if (e.obj) {
        e.obj.fn();
      } else {
        this.$toast.fail('请选择下一步操作哦');
      }
    },
    del() {
      Dialog.confirm({
        title: '删除提醒',
        message: '删除后数据无法找回，是否确认删除？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/settle/delShrimp', {
            ss_key: this.form.ss_key,
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除种虾批次成功');
              this.$go(-1, this);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    edit() {
      this.$toPage(this, 'sBatchEdit', {
        ss_key: this.form.ss_key,
      });
    },
    notice() {
      let _this = this;
      let p = 1;
      let title = '';
      let remark = [
        {
          label: '种虾批次',
          value: _this.form.batch,
        },
        {
          label: _this.form.mc_name, // 苗场名
          value: `公${_this.form.male_surplus}；母${_this.form.female_surplus}`,
        },
      ];
      _this.toNotice({
        p,
        title,
        remark,
        re: [
          {
            type: '2',
            re_info: {
              ss_key: _this.form.ss_key,
            },
          },
        ],
      });
    },
    checkAll(val, refName) {
      // if (this.isPool) {
      this.$refs[refName][0].toggleAll(val);
      // this.wsPoolChosedList = deepCopy(this.wsPoolChosedList)
      // }
    },
    allowPoolChoose(item) {
      this.activeName = item['p_key'];
      this.judgeWsAll();
    },
    judgeWsAll() {
      this.wsPoolChosedList &&
        this.wsPoolChosedList.forEach((item) => {
          if (
            item.p_chosed &&
            item.p_list &&
            item.p_chosed.length == item.p_list.length
          ) {
            item.ws_checked = true;
          } else {
            item.ws_checked = false;
          }
        });
    },
    changeIsPool() {
      if (this.isPool == false) {
        this.wsPoolChosedList &&
          this.wsPoolChosedList.forEach((item) => {
            item.p_chosed = [];
          });
        this.judgeWsAll();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.chose-ws {
  margin: 0.5rem 0;

  .FlexCenter;
  background: rgba(@mainRgb, 0.1);
  height: 48px;
}
</style>
