<template>
  <div class="scroll-tag">
    <van-tabs
      v-model="active"
      line-height="0"
      @click="tabClick"
      ref="tabs"
      :swipe-threshold="swipeThreshold"
    >
      <van-tab v-for="(item, index) in tmpOpts" :key="index" :name="item.name">
        <template #title>
          <div class="title" :class="{ active: tmpChosed.includes(item.name) }">
            {{ item.label }}
          </div>
        </template>
        <template #default>
          <!-- 纯属占位，不能删，不然vant报错 -->
          {{ '' }}
        </template>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { objToArr } from '@/utils/utils';
export default {
  name: 'scrollTag',
  props: {
    opts: {
      type: [Array, Object],
      default: () => [
        {
          name: '1',
          label: '111',
          show: false,
        },
        {
          name: 'a',
          label: 'aaa',
          show: true,
        },
        {
          name: 'b',
          label: 'bbb',
          show: true,
        },
      ],
    },
    chosed: {
      // sync
      type: [Array, String],
      default: () => [],
      required: true,
    },
    parentClick: {
      type: Boolean,
      default: false, // true时则由父组件自己做数据处理
    },
    single: {
      type: Boolean,
      default: false,
    },
    swipeThreshold: {
      type: String,
      default: '3',
    },
  },
  data() {
    return {
      tmpOpts: [],
      active: undefined,
    };
  },
  computed: {
    tmpChosed() {
      if (Array.isArray(this.chosed) || this.single) return this.chosed;
      else return [this.chosed];
    },
  },
  mounted() {
    if (Array.isArray(this.opts)) {
      this.tmpOpts = this.opts.filter((item) => item.show != false);
    } else {
      this.tmpOpts = objToArr(this.opts, 'name', 'label');
    }
  },
  watch: {
    opts(val) {
      if (!Array.isArray(val)) {
        this.tmpOpts = objToArr(val, 'name', 'label');
      } else {
        this.tmpOpts = val.filter((item) => item.show != false);
      }
    },
  },
  methods: {
    tabClick(name) {
      if (this.parentClick) {
      } else if (!this.single) {
        let tmp = JSON.parse(JSON.stringify(this.tmpChosed));
        let index = tmp.indexOf(name);
        if (index == -1) {
          tmp.push(name);
        } else {
          tmp.splice(index, 1);
        }
        this.$emit('update:chosed', tmp);
      } else if (this.single) {
        if (this.tmpChosed != name) {
          this.$emit('update:chosed', name);
        } else {
          this.$emit('update:chosed', undefined);
        }
      }

      this.$emit('click', name);
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-tag {
  width: 100%;
}
.title {
  padding: 0 1rem;
  border-radius: 8px;
  border: 1px solid @lineColor;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  color: @textColor;
}
.active {
  background: rgba(@mainRgb, 0.8);
  border: none;
  color: #fff;
  font-size: bold;
}

/deep/.van-tabs__line {
  display: none;
}
</style>
