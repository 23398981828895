<template>
  <div class="main relative" v-myLoad="isLoading">
    <yiyu-bar tab-title="种虾批次详情"></yiyu-bar>
    <div class="main-content relative">
      <div class="bold-title">
        {{ form.batch }}
        <y-tag-conf
          :status="form.status"
          :labels="$store.state.basic.sBatchStatusLabels"
        ></y-tag-conf>
      </div>
      <div class="handle-btn-wrap">
        <template v-for="(item1, index1) in getHandles()">
          <div
            class="handle-btn"
            :key="index1 + 'btn'"
            @click="item1['callback']()"
            v-if="item1.show != false"
          >
            <add-btn
              :add="item1.add == true"
              :src="item1.src"
              :bgColor="item1.bgColor"
            ></add-btn>
            <div class="txt">{{ item1.text }}</div>
          </div>
        </template>
        <van-popover
          placement="left"
          v-model="moreShow"
          theme="dark"
          trigger="click"
          :actions="FILTER_HANDLE(actions)"
          @select="moreSelect($event)"
        >
          <template #reference>
            <!-- 在移动端才能实现完美交互 -->
            <div class="handle-btn">
              <add-btn
                :add="false"
                :src="require('@/assets/icons/phase.png')"
              ></add-btn>
              <div class="txt">{{ '更多' }}</div>
            </div>
          </template>
        </van-popover>
      </div>
      <yiyu-detail :formOpts="formOpts" class="relative">
        <template #top>
          <section class="relative">
            <data-field :dataObj="dataObj"></data-field>
            <transition-group name="list-fade" tag="div">
              <div class="content" v-if="pack_list.length" key="pack">
                <div class="topTitle">打包安排</div>
                <template>
                  <div v-for="(item, index) in pack_list" :key="index">
                    <div>
                      <span
                        class="default-state"
                        :class="{ state: item.lm_id }"
                      ></span>
                      <span>{{ item.launch_date }}</span>
                    </div>
                    <data-field :dataObj="packDataObj(item)"></data-field>
                    <see-more @change="topMore"></see-more>
                  </div>
                </template>
              </div>

              <!-- 特定的操作记录 -->
              <YCard
                class="content"
                v-for="(operation, key) in specificOperationsCards"
                :key="operation.title"
                :title="operation.title"
                :actions="operation.actions(key)"
              >
                <GridBox :value="operation.data" />
              </YCard>

              <!-- 每日填写 -->
              <YCard
                class="content"
                title="每日填写"
                :actions="dailyActions"
                key="daily"
              >
                <template #title-slot>
                  <span
                    class="default-state"
                    :class="{
                      state: info_status == 2 || info_status == '2',
                    }"
                    >{{ dailyState }}</span
                  >
                </template>

                <div v-if="day_info.is_new == 2">
                  <div>
                    <div class="newData">
                      最新数据 : {{ day_info.record_date }}
                    </div>

                    <data-field :dataObj="data_info" type="grid-box" />
                  </div>
                </div>
                <div v-else>
                  <van-divider>暂无数据</van-divider>
                </div>
              </YCard>

              <div class="content" key="feed">
                <div class="topLayout">
                  <div class="topTitle">
                    投喂<span v-if="seed_list.seed_count"
                      >（{{ seed_list.seed_count }}次）</span
                    >
                  </div>
                  <div @click="toPage('addCul')">
                    <van-icon class="icon" name="plus"></van-icon>
                    <span>新增</span>
                  </div>
                </div>
                <div v-if="seed_list.seed_title">
                  <div class="bottomLayout">
                    <div class="ShallowText">最新投喂</div>
                    <div class="bottomLayoutItem">
                      {{ $dayjs(seed_list.seed_date).format('MM/DD HH:mm') }}
                    </div>
                    <div class="bottomLayoutItem">
                      {{ seed_list.seed_title }}
                    </div>
                  </div>
                  <div class="row-between" @click="toPage('techList')">
                    <div></div>
                    <div>
                      更多<van-icon class="icon" name="arrow"></van-icon>
                    </div>
                  </div>
                </div>
                <van-divider v-else>暂无数据</van-divider>
              </div>

              <div class="content" key="qa">
                <div class="topLayout">
                  <div class="topTitle">
                    质检<span v-if="qc_list.qc_count"
                      >（{{ qc_list.qc_count }}次）</span
                    >
                  </div>
                  <div @click="toPage('addDect')">
                    <van-icon class="icon" name="plus"></van-icon>
                    <span>新增</span>
                  </div>
                </div>

                <div v-if="qc_list.t_apply_times">
                  <div class="bottomLayout">
                    <div class="bottomLayoutItem">
                      <span class="ShallowText">质检申请：</span
                      ><span>{{ qc_list.t_apply_times || '暂无' }}</span>
                    </div>
                    <div class="bottomLayoutItem">
                      <span class="ShallowText">已质检：</span
                      ><span>{{ qc_list.t_t_time || '暂无' }}</span>
                    </div>
                    <div class="bottomLayoutItem">
                      <span class="ShallowText">待质检：</span
                      ><span>{{ qc_list.t_nt_time || '暂无' }}</span>
                    </div>
                  </div>
                  <div class="row-between" @click="toPage('dectList')">
                    <div></div>
                    <div>
                      更多<van-icon class="icon" name="arrow"></van-icon>
                    </div>
                  </div>
                </div>
                <van-divider v-else>暂无数据</van-divider>
              </div>
            </transition-group>
          </section>
        </template>

        <template #pool>
          <section class="relative">
            <template v-if="form.male && form.female">
              <div>
                <div class="topTitle">公虾</div>
                <data-field
                  class="dailyDataField"
                  :dataObj="malePoolObj"
                ></data-field>
                <div class="topTitle">母虾</div>
                <data-field
                  class="dailyDataField"
                  :dataObj="femalePoolObj"
                ></data-field>
              </div>
              <div class="row-between">
                <div></div>
                <div class="main-c more" @click="toPage('sPoolList')">更多</div>
              </div>
            </template>
            <template v-else>
              <van-divider>暂无数据</van-divider>
            </template>
          </section>
        </template>
        <template #log>
          <!-- <section class="relative" v-myLoad="logLoading"> -->
          <template v-if="list.length !== 0">
            <van-list
              v-model="logLoading"
              :finished="logListParams.finished"
              finished-text="没有更多了"
              :error.sync="logListParams.error"
              error-text="请求失败，点击重新加载"
              @load="getLog"
            >
              <div
                v-for="(value, key) in list"
                :key="key"
                @click="
                  toPage(value.type, value.out_key, value.data.out_deleted_at)
                "
              >
                <div class="detailTitle">{{ detailTitle[value.type] }}</div>
                <div class="log ShallowText" v-if="value.opt_date">
                  记录时间 :
                  {{ recordTime(value.opt_date) }}
                  <van-icon
                    v-if="value.data.out_deleted_at"
                    class="delete"
                    size="22"
                    name="delete-o"
                    @click.stop="delLog(value.ls_key)"
                  />
                </div>
                <div class="log ShallowText" v-if="value.opt_user">
                  操作人 :
                  {{ recordTime(value.opt_user) }}
                </div>
                <div
                  v-for="(item, index) in listDetail(key)"
                  :key="index"
                  class="log"
                >
                  <div class="flex">
                    <div class="whitespace-nowrap">{{ item.label }} :</div>
                    <div class="pl-1">{{ item.val | checkEmpty }}</div>
                  </div>
                </div>
                <div class="logDateDel" v-if="value.data.out_deleted_at">
                  数据已被删除
                </div>
              </div>
            </van-list>
          </template>
          <template v-else>
            <van-divider>暂无日志</van-divider>
          </template>
          <!-- </section> -->
        </template>
      </yiyu-detail>
    </div>
  </div>
</template>

<script>
import addBtn from '@/components/button/addBtn';
import seeMore from '@/components/seeMore/seeMore.vue';
import yiyuBar from '@/components/navbar';
import yiyuDetail from '@/components/detail';
import dataField from '@/components/layout/dataField';
import { Dialog, List } from 'vant';
import { YTagConf } from 'yiyu-vant-ui/lib/tag';
import { checkEmpty } from '@/filters';
import { batchDailyData } from './batchDailyData';
import GridBox from '@/components/layout/gridBox.vue';
import YCard from '@/components/card/Card.vue';
import { keyBy, omit, pickBy } from 'lodash-es';
import { SSLogToGridBox } from './getSSLog';

export default {
  name: 'sBatchDetail',
  components: {
    yiyuDetail,
    dataField,
    yiyuBar,
    seeMore,
    addBtn,
    YTagConf,
    GridBox,
    YCard,
    [List.name]: List,
  },
  filters: {
    checkEmpty,
  },
  data() {
    return {
      specificOperations: {
        10: {
          title: '淘汰',
          data: [],
          key: '',
          actions: () => [
            {
              text: '编辑',
              fn: () => {
                const { ss_key } = this;
                this.$toPage(this, 'sBatchEdit', {
                  form_type: 'over',
                  ss_key,
                });
              },
            },
            {
              text: '删除',
              fn: async () => {
                // 重置种虾批次
                try {
                  await Dialog({
                    title: '删除淘汰',
                    message: '确认删除淘汰记录吗',
                    showCancelButton: true,
                  });

                  await this.$post('/settle/resetShrimp', {
                    ss_key: this.ss_key,
                  });

                  this.getSpecificLog();
                } catch (error) {
                  // 不删除
                  console.debug(error);
                }
              },
            },
          ],
        },
        11: {
          title: '投放安排',
          data: [],
          actions: () => [
            {
              text: '更多',
              fn: () => {
                const {
                  rawData: { record_date },
                } = this.specificOperations['11'];
                const {
                  ss_key,
                  form: { batch: ss_batch },
                } = this;
                this.$toPage('sArrangeList', {
                  ss_key,
                  ss_batch,
                  start_at: this.$dayjs(record_date)
                    .subtract(5, 'day')
                    .toISOString(),
                  end_at: this.$dayjs(record_date).add(5, 'day').toISOString(),
                });
              },
            },
          ],
        },
        12: {
          title: '排幼体',
          data: [],
          actions: () => [
            {
              text: '更多',
              fn: () => {
                const {
                  ss_key,
                  form: { batch: ss_batch },
                } = this;
                this.$toPage('sArrangeList', {
                  ss_key,
                  ss_batch,
                });
              },
            },
          ],
        },
      },
      isLoading: false,

      logLoading: false,
      ss_key: undefined,
      moreShow: false,
      actions: [
        // 常用操作-更多
        { text: '交配' },
        { text: '孵化' },
        { text: '淘汰' },
        { text: '编辑', handle: 'SEED_BATCH_EDIT' },
        { text: '删除', handle: 'LARVA_BATCH_DEL' },
      ],
      dailyActions: [
        {
          text: '每日数据',
          fn: () => {
            this.dailyOpt(this.form, 'all');
          },
        },
        {
          text: '交配信息',
          fn: () => {
            this.dailyOpt(this.form, 'mating');
          },
        },
        {
          text: '孵化信息',
          fn: () => {
            this.dailyOpt(this.form, 'hatch');
          },
        },
        {
          text: '损耗信息',
          fn: () => {
            this.dailyOpt(this.form, 'loss');
          },
        },
        {
          text: '更多',
          fn: () => {
            this.toPage('sDailyList');
          },
        },
      ],
      list: [],
      logListParams: {
        finished: false,
        error: false,
        total: 0,
        page: 1,
        pageSize: 10,
      },
      detailTitle: {
        1: '安排',
        2: '接虾',
        3: '投喂',
        4: '质检',
        5: '每日',
        6: '损耗',
        7: '交配',
        8: '孵化',
        9: '淘汰',
        10: '淘汰',
        11: '投放安排',
        12: '排幼体',
      },
      form: {},
      seed_list: [],
      qc_list: [],
      pack_list: [],
      packData: [],
      isTopMore: false,
      postLoading: false,
      day_info: {
        info_status: '1',
      },
    };
  },

  activated() {
    const { ss_key } = this.$route.query;
    if (ss_key) this.ss_key = ss_key;
    this.list = [];
    this.isLoading = true;
    this.logListParams = {
      finished: false,
      error: false,
      total: 0,
      page: 1,
      pageSize: 10,
    };
    console.log(1, this.logListParams);

    this.getData();

    this.getSpecificLog();
  },
  created() {
    this.pack_list = this.packData.slice(0, 3);
  },
  computed: {
    specificOperationsCards({ specificOperations }) {
      // data 的长度大于 0 才返回。
      return pickBy(specificOperations, (obj) => obj.data.length);
    },

    info_status() {
      return this.day_info?.info_status;
    },

    formOpts() {
      return [
        {
          slot: 'top',
        },
        {
          title: '池数据',
          slot: 'pool',
        },
        {
          title: '详情',
          slot: 'log',
        },
      ];
    },
    listDetail() {
      return (key) => {
        let newList = [];
        Array.isArray(this.list) &&
          this.list.forEach((item, index) => {
            if (item.type == '2' && index == key) {
              newList = [
                {
                  label: '数量',
                  val: `公${item.data?.male_count};母${item.data?.female_count}`,
                },
                {
                  label: '池数',
                  val: `公${item.data?.male_p_count};母${item.data?.female_p_count}`,
                },
                {
                  label: '车间',
                  val: Array.isArray(item.data?.ws_list)
                    ? item.data?.ws_list.toString() || '暂无'
                    : item.data?.ws_list,
                },
              ];
            }

            if (item.type == (3 || 4) && index == key) {
              newList = [
                {
                  label: '标题',
                  val: item.data?.title,
                },
              ];
            }
            if (item.type == '5' && index == key) {
              newList = [
                {
                  label: '损耗量',
                  val: `公${item.data?.male_count};母${item.data?.female_count}`,
                },
                {
                  label: '成熟数量',
                  val: `${item.data?.mature_count}`,
                },
                {
                  label: '交配数量',
                  val: `${item.data?.mating_count}`,
                },
                {
                  label: '预估产量',
                  val: `${item.data?.e_yield}`,
                },
                {
                  label: '实际产量',
                  val: `${item.data?.real_yield}`,
                },

                {
                  label: '受精率',
                  val: `${item.data?.fer_rate}%`,
                },
              ];
            }
            // 损耗
            if (item.type == 6 && index == key) {
              newList = [
                {
                  label: '损耗量',
                  val: `公${item.data?.male_count};母${item.data?.female_count}`,
                },
              ];
            }

            //交配
            if (item.type == 7 && index == key) {
              newList = [
                {
                  label: '交配数',
                  val: item.data?.mating_count,
                },
                {
                  label: '成熟数',
                  val: item.data?.mature_count,
                },
              ];
            }
            // 孵化
            if (item.type == 8 && index == key) {
              newList = [
                {
                  label: '预估产量',
                  val: item.data?.e_yield,
                },
                {
                  label: '实际产量',
                  val: item.data?.real_yield,
                },

                {
                  label: '受精率',
                  val: `${item.data?.hatch_rate}%`,
                },
              ];
            }

            // 淘汰
            if (item.type == 9 && index == key) {
              newList = [
                {
                  label: '淘汰时间',
                  val: item.data?.end_date,
                },
                {
                  label: '备注',
                  val: item.data?.remark,
                },
              ];
            }
          });

        return newList;
      };
    },
    dataObj() {
      return [
        [
          {
            hide: this.form.male_count == 0 && this.form.female_count == 0,
            data: `公${this.form.male_count || '：暂无'}，母${
              this.form.female_count || '：暂无'
            }`,
            descText: '当前数量',
          },
          {
            hide: this.form.breed_date == 0,
            data: this.form.breed_date,
            unitText: '天',
            descText: '育苗天数',
          },
          {
            hide:
              this.form.loss_female_count == 0 &&
              this.form.loss_female_count == 0,
            data: `公${this.form.loss_male_count || '：暂无'}，母${
              this.form.loss_female_count || '：暂无'
            }`,
            descText: '累计损耗',
          },
        ],
        [
          {
            hide: this.form.alias == '' || this.form.alias == undefined,
            data: this.form.alias,
            descText: '别名',
          },
          {
            hide: this.form.strain == '' || this.form.strain == undefined,
            data: this.form.strain,
            descText: '品系',
          },
          {
            hide: this.form.mc_name == '' || this.form.mc_name == undefined,
            data: this.form.mc_name,
            descText: '养殖地',
            callback: () => this.toPage('sBaseDetail'),
          },
        ],
        [
          {
            hide:
              this.form.ws_list == '' ||
              this.form.ws_list == [] ||
              this.form.ws_list == undefined,
            data: this.form?.ws_list?.toString(),
            descText: '养殖车间',
          },
          {
            hide:
              this.form.mating_count == '' ||
              this.form.mating_count == undefined,
            data: this.form.mating_count,
            unitText: '次',
            descText: '交配次数',
          },
          {
            hide:
              this.form.real_yields == '' || this.form.real_yields == undefined,
            data: this.form.real_yields,
            unitText: '万',
            descText: '累计产量',
          },
        ],
        [
          {
            hide: this.form.launch == '' || this.form.launch == undefined,
            data: this.form.launch,
            unitText: '万',
            descText: '已安排幼苗',
          },
        ],
      ];
    },
    data_info() {
      return batchDailyData(this.day_info);
    },
    malePoolObj() {
      return [
        [
          {
            data: this.form.male.count,
            descText: '入池量',
          },
          {
            data: this.form.male.p_count,
            descText: '池数',
          },
          {
            data: this.form.male.surplus,
            descText: '当前数量',
          },
        ],
      ];
    },
    femalePoolObj() {
      return [
        [
          {
            data: this.form.female.count,
            descText: '入池量',
          },
          {
            data: this.form.female.p_count,
            descText: '池数',
          },
          {
            data: this.form.female.surplus,
            descText: '当前数量',
          },
        ],
      ];
    },
    packDataObj() {
      return (item) => {
        return [
          [
            {
              data: item.launch,
              unitText: '万',
              descText: '育苗数量',
            },
            {
              data: item.density,
              unitText: '万/包',
              descText: '打包密度',
            },
            {
              data: item.mc_key,
              descText: '送往',
            },
          ],
        ];
      };
    },

    dailyState() {
      if (
        this.day_info?.info_status == 2 ||
        this.day_info?.info_status == '2'
      ) {
        return '今日已填';
      } else {
        return '今日待填';
      }
    },
  },
  methods: {
    richCardActions(operation) {
      return operation.actions;
    },

    recordTime(date) {
      return date.slice(0, 16);
    },

    delLog(key) {
      this.$dialog
        .confirm({
          title: '删除',
          message: '是否确认要该条记录',
        })
        .then(() => {
          this.$post('/settle/delSSLog', { ls_key: key }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除记录成功');
              this.getLog();
            }
          });
        });
    },

    fetchLog(params, options = {}) {
      const { page, pageSize } = this.logListParams;
      // 预处理参数
      let _params = Object.assign(
        {
          ss_key: this.ss_key,
          page,
          count: pageSize,
        },
        params
      );

      if (options.disablePage) _params = omit(_params, ['page', 'count']);

      return this.$get('/settle/getSSLog', _params);
    },

    getLog() {
      this.fetchLog()
        .then((res) => {
          if (res.code == 10000) {
            let { list, total } = res.data;

            this.list = this.list.concat(list);
            this.logListParams.total = total;

            if (this.list.length >= total) this.logListParams.finished = true;
            else this.logListParams.page++;
          } else {
            this.logListParams.error = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.logListParams.error = true;
        })
        .finally(() => {
          this.logLoading = false;
        });
    },

    // 获取特定的日志，比如 淘汰，排幼体，投放
    getSpecificLog() {
      this.fetchLog(
        {
          type: ['10', '11', '12'],
        },
        { disablePage: true }
      )
        .then(({ code, data }) => {
          if (code !== 10000) return;
          const { list } = data;
          const o = keyBy(list, 'type');

          for (const type of Object.keys(this.specificOperations)) {
            this.specificOperations[type].rawData = o[type]?.data;
            this.specificOperations[type].data = SSLogToGridBox(
              type,
              o[type]?.data
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    toPage(type, key, noToPage) {
      // 保证 type 类型
      type = String(type);

      if (!noToPage) {
        switch (type) {
          case 'sPoolList':
            this.$toPage(this, type, {
              mc_key: this.form.mc_key,
              ss_key: this.form.ss_key,
            });
            break;
          case 'sDailyList':
            this.$toPage(this, type, this.form);
            break;
          case 'sBaseDetail':
            this.$toPage(this, type, { mc_key: this.form.mc_key });
            break;
          case 'addCul':
            this.culOpt();
            break;
          case 'addDect':
            this.dectOpt();
            break;
          case '2':
            // 接虾
            this.$toPage(this, 'sBatchShrimpEdit', { ss_key: key });
            break;
          case '3':
            // 育苗
            this.$toPage(this, 'techDetail', { r_key: key });
            break;
          case '4':
            // 质检
            this.$toPage(this, 'dectDetail', { r_key: key });
            break;

          case '9':
            //  淘汰：跳转到编辑淘汰信息页面

            this.$toPage(this, 'sBatchEdit', { sr_key: key });
            break;
          case '11':
          case '12':
            this.$toPage('sArrangeList');
            break;
          case 'techList':
            this.$toPage(this, 'techList', {
              ss_key: this.form.ss_key,
              ws_type: 2,
              test_type: 2,
            });
            break;
          case 'dectList':
            this.$toPage(this, 'dectApplyList', {
              ss_key: this.form.ss_key,
              ws_type: 2,
              test_type: 1,
            });
            break;
          default:
            this.$toPage(this, 'sDailyDetail', {
              sr_key: key,
              ss_key: this.ss_key,
            });
            break;
        }
      }
    },
    // 获取常用操作
    getHandles() {
      return this.FILTER_HANDLE([
        {
          src: require('@/assets/icons/dect.png'),
          text: '投喂',
          add: true,
          bgColor: '#1296db',
          callback: this.culOpt,
          handle: 'SEED_BATCH_CUL',
        },
        {
          src: require('@/assets/icons/estimate.png'),
          text: '质检申请',
          callback: this.dectOpt,
          add: true,
          bgColor: '#1296db',
          handle: 'SEED_BATCH_DECT',
        },
        {
          src: require('@/assets/icons/important.png'),
          text: '每日',
          callback: () => this.dailyOpt(this.form, 'all'),
          add: true,
          bgColor: '#1296db',
        },

        {
          src: require('@/assets/icons/sale.png'),
          text: '损耗',
          callback: () => this.dailyOpt(this.form, 'loss'),
          add: true,
          bgColor: '#1296db',
        },
      ]);
    },
    culOpt() {
      this.$toPage(this, 'techEdit', {
        mc_key: this.form.mc_key,
        ss_key: this.ss_key,
        test_type: '2',
        ws_type: '2',
        newest_r_key: this.seed_list.newest_r_key,
      });
    },
    dectOpt() {
      this.$toPage(this, 'dectApplyEdit', {
        mc_key: this.form.mc_key,
        ss_key: this.ss_key,
        test_type: '1',
        ws_type: '2',
      });
    },
    dailyOpt(item = {}, type) {
      this.$toPage(this, 'sDailyEdit', {
        ss_key: this.ss_key,
        sr_key: item.sr_key,
        type,
      });
    },
    // 常用操作-更多
    moreSelect(action) {
      switch (action.text) {
        case '交配':
          this.dailyOpt(this.form, 'mating');
          break;
        case '孵化':
          this.dailyOpt(this.form, 'hatch');
          break;
        case '淘汰':
          this.$toPage(this, 'sBatchEdit', {
            form_type: 'over',
            ss_key: this.ss_key,
          });
          break;
        case '删除':
          Dialog.confirm({
            title: '删除提醒',
            message: '删除后数据无法找回，是否确认删除？',
          })
            .then(() => {
              if (this.postLoading) return;
              this.postLoading = true;
              this.$post('/settle/delShrimp', {
                ss_key: this.ss_key,
              }).then((res) => {
                this.postLoading = false;
                if (res.code == 10000) {
                  this.$toast.success('删除种虾批次成功');
                  this.$go(-1, this);
                }
              });
            })
            .catch(() => {
              // on cancel
            });
          break;
        case '编辑':
          this.$toPage(this, 'sBatchEdit', {
            ss_key: this.ss_key,
          });
          break;
      }
    },
    getData() {
      this.getLog();

      this.$get('/settle/getShrimpOne', { ss_key: this.ss_key }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          this.form = res.data;
          this.packData = res.data.pack_list;
          this.seed_list = res.data.seed_list;
          this.qc_list = res.data.qc_list;
          this.day_info = res.data.day_info;
        }
      });
    },
    topMore() {
      this.isTopMore = !this.isTopMore;
      if (this.isTopMore) {
        this.pack_list = this.packData;
      } else {
        this.pack_list = this.packData.slice(0, 3);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 0.5rem;
  padding-bottom: 5rem;
  .bold-title {
    color: @titleColor;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .logDateDel {
    font-weight: bold;
    color: #f84e40;
    text-align: right;
    margin-right: 0.5rem;
  }
  .delete {
    text-align: right;
  }
  .handle-btn-wrap {
    display: flex;
    background: #fff;
    padding: 8px 16px;
    flex-wrap: wrap;
    justify-content: space-between;

    .handle-btn {
      // width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem 0;

      .txt {
        white-space: nowrap;
      }
    }
  }
  .detailTitle {
    margin: 1rem 0 0.5rem 1rem;
    font-weight: bold;
  }
}
.topTitle {
  margin: 0.3rem 0 0.3rem 0;
  color: @titleColor;
  font-size: 1.1rem;
  font-weight: 600;
}
.icon {
  opacity: 0.7;
}
.content {
  background-color: rgb(245, 245, 245);
  border-radius: 0.3rem;
  padding: 0.5rem;
  margin: 0.4rem;
  transition: all 0.5s;
}
.newData {
  margin-top: 0.3rem;
  color: rgba(16, 16, 16, 0.43);
}
.more {
  margin-right: 0.6rem;
}

.default-state {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  &::before {
    display: flex;
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.2rem;
    background-color: #df1d34;
  }
}
.state::before {
  background-color: #07c160;
}
.topLayout {
  display: flex;
  justify-content: space-between;
}
.bottomLayout {
  display: flex;
  flex-wrap: wrap;
  .bottomLayoutItem {
    margin-left: 0.5rem;
  }
}
.log {
  margin-left: 1.4rem;

  display: flex;
  justify-content: space-between;
}
.dailyDataField {
  padding: 1.2rem;
}
</style>
