<template>
  <div class="main">
    <yiyu-bar :tab-title="tabTitle"></yiyu-bar>
    <div class="row-between">
      <div class="yiyu-bold-title">{{ form.name }}</div>
      <handle-popver :actions="actions"></handle-popver>
    </div>
    <el-table
      :header-cell-style="{ background: '#fafafa' }"
      stripe
      ref="table"
      :data="list"
      size="mini"
      @cell-click="cellClick"
    >
      <!-- TODO:鹏英：表头处理 -->
      <el-table-column
        v-for="(value, index) in listTitle"
        :key="index"
        :prop="value.prop"
        :label="value.label"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <template v-if="value.prop === 'record_date'">
            <span class="mainColor">{{ scope.row[value.prop] || '合计' }}</span>
          </template>
          <template v-else-if="value.prop === 'actual_yield'">
            <span class="mainColor">{{
              scope.row[value.prop].toFixed(2)
            }}</span>
          </template>
          <template v-else-if="value.prop === 'mating_rate'">
            <span class="mainColor">{{
              scope.row[value.prop].toFixed(2)
            }}</span>
          </template>
          <template v-else-if="value.prop === 'unit_yield'">
            <span class="mainColor">{{
              scope.row[value.prop] && scope.row[value.prop].toFixed(2)
            }}</span>
          </template> -->
          <template>{{ scope.row[value.prop] }}</template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import yiyuBar from '@/components/navbar'
import HandlePopver from '@/components/feedback/handlePopver.vue'
import exportExcel from '@/utils/exportExcel'
export default {
  name: 'sDailyList',
  components: {
    yiyuBar,
    HandlePopver,
  },
  data() {
    return {
      tabTitle: '每日数据',
      actions: [
        {
          text: '每日数据',
          fn: () => {
            this.dailyOpt('all')
          },
        },
        {
          text: '交配信息',
          fn: () => {
            this.dailyOpt('mating')
          },
        },
        {
          text: '孵化信息',
          fn: () => {
            this.dailyOpt('hatch')
          },
        },
        {
          text: '损耗信息',
          fn: () => {
            this.dailyOpt('loss')
          },
        },
        {
          text: '导出报表',
          fn: () => {
            let title = this.form.name + this.tabTitle
            let sheetDatas = [
              {
                title,
                header: this.listTitle,
                data: this.list,
              },
            ]
            exportExcel({ fileName: title, sheetDatas })
          },
        },
        {
          text: '发送通知',
          fn: () => {
            this.toNotice({
              p: '1',
              title: this.form.name + this.tabTitle,
              re: [
                {
                  type: 8,
                  re_info: {
                    ss_key: this.ss_key,
                  },
                },
              ],
            })
          },
        },
      ],
      listTitle: [
        {
          label: '日期',
          prop: 'record_date',
        },
        {
          label: '育苗天数',
          prop: 'l_date',
        },
        {
          label: '损耗(母)',
          prop: 'female_loss',
        },
        {
          label: '损耗(公)',
          prop: 'male_loss',
        },
        {
          label: '成熟母虾',
          prop: 'mature_count',
        },
        {
          label: '交配母虾',
          prop: 'mating_count',
        },
        {
          label: '产卵母虾',
          prop: 'matrix_count',
        },

        {
          label: '交配率',
          prop: 'mating_rate',
        },

        {
          label: '今日产量',
          prop: 'real_yield',
        },

        {
          label: '今日单产',
          prop: 'actual_yield',
        },
        {
          label: '幼体安排',
          prop: 'arrange',
        },
        {
          label: '排幼体',
          prop: 'count',
        },
        {
          label: '预估明日产量',
          prop: 'e_yield',
        },
        {
          label: '预估明日单产',
          prop: 'unit_yield',
        },
      ],
      list: [],
      total: {},
      ss_key: undefined,
      form: {},
    }
  },

  created() {
    if (this.$route.query) {
      let query = this.$route.query
      this.ss_key = query.ss_key
      if (this.ss_key) {
        this.getData()
      }
    }
  },

  methods: {
    getHeaderData() {
      return this.header
    },
    getAllData() {
      return this.tableData
    },
    adjustColumnWidth(el) {
      let widthList = []

      el.querySelectorAll('tr').forEach((tr) => {
        tr.querySelectorAll('span').forEach((td, i) => {
          if (!widthList[i]) {
            widthList[i] = []
          }

          widthList[i].push(td.getBoundingClientRect().width + 16)
        })
      })

      return widthList.map((width) => Math.max(...width))
    },
    type() {
      this.headerData = this.getHeaderData()
      this.allData = this.getAllData()
      this.loading = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.colWidthList = this.adjustColumnWidth(this.$refs['table'].$el)
          this.loading = false
        })
      })
    },
    dailyOpt(type) {
      this.$toPage(this, 'sDailyEdit', {
        ss_key: this.ss_key,
        type,
      })
    },
    cellClick(row) {
      if (row.ss_key && row.sr_key) {
        this.$toPage(this, 'sDailyDetail', {
          ss_key: row.ss_key,
          sr_key: row.sr_key,
        })
      }
    },
    getData() {
      let params = {
        ss_key: this.ss_key,
      }
      this.$get('/record/getList', params).then((res) => {
        if (res.code == 10000) {
          this.list = res.data.list
          this.total = res.data.total
          this.total.record_date = '合计'
          this.list.forEach((item) => {
            item.record_date = item.record_date.substring(5, 10)
          })
          this.total['l_date'] = '-'
          this.list.unshift(this.total)
          this.form = res.data
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.row-between {
  padding: 1rem;
}
.eltable {
  width: 100%;
}
</style>
