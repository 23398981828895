import { merge, intersection, isUndefined, mapValues, pickBy } from 'lodash-es'
// 对聚合操作逻辑进行封装

// 该文件默认导出一个函数, 该函数返回一个函数, 这个返回的函数可以理解成聚合函数
// 往聚合函数内传入需要聚合选项, 聚合函数返回聚合后参数数组.

// config 说明
// 查阅 shoWhen 字段, 如果没有 showWhen, 则该项永久显示
// 若有 shoWhen, 根据 shoWhen 配置和给入聚合选项进行聚合
// 比如, 聚合 tag, 则聚合没有 shoWhen 和 shoWhen 含有
// tag 的字段
/**
 *
 * @param {*} config
 * @returns {function name(params):string[] {}}
 */
export default function (config) {
  const _o = mapValues(config, 'shoWhen')
  // fieldArr 是一个字符串数组.
  return function (fieldArr) {
    return fieldArr.length > 0
      ? Object.keys(
          merge(
            pickBy(_o, (item) => {
              return intersection(item, fieldArr).length > 0
            }),
            pickBy(_o, isUndefined),
          ),
        )
      : Object.keys(_o)
  }
}
