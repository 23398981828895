<template>
  <div class="yiyu-select-tag">
    <template v-if="classify">
      <!-- 需分类 -->
      <div
        class="classify-op-wrap"
        v-for="(item, key, index) in classifyObj"
        :key="index"
      >
        <div class="row">
          <div class="left">{{ item.label }}</div>
          <template v-if="!single"
            ><div class="right" @click="choseAll(key)">
              {{ item.isAll ? '取消全选' : '全选' }}
            </div></template
          >
        </div>
        <span
          class="tag"
          v-for="(item1, index1) in item.child"
          :key="index1"
          :style="{
            background: background(item1[keyName]),
            color: color(item1[keyName]),
          }"
          @click="chose(item1)"
          >{{
            addValueKey
              ? item1[valueKey] + '_' + item1[addValueKey]
              : item1[valueKey]
          }}</span
        >
      </div>
    </template>
    <template v-else>
      <!-- 无需分类 -->
      <span
        v-if="!single"
        class="tag"
        :style="{
          background: newIsCheckAll ? '#fff1ea' : '#f1f3f5',
          color: newIsCheckAll ? 'rgb(255, 117, 46)' : 'rgb(82, 98, 124)',
        }"
        @click="newCheckAllClick"
        >不限</span
      >
      <span
        class="tag"
        v-for="(item, index) in opts"
        :key="index"
        :style="{
          background: background(item[keyName]),
          color: color(item[keyName]),
        }"
        @click="chose(item)"
        >{{
          addValueKey
            ? item[valueKey] + '_' + item[addValueKey]
            : item[valueKey]
        }}</span
      >
    </template>
  </div>
</template>

<script>
import { getKeyObj } from '@/utils/utils';
export default {
  name: 'selectTag',
  // 触发的事件
  // change 返回 keys 及 keys所在的对象们
  props: {
    valueKey: {
      type: String,
      default: 'val',
    },
    addValueKey: {
      // 用来将两个value进行拼接
      type: String,
    },
    keyName: {
      type: String,
      default: 'key',
    },
    opts: {
      // 注意:要是支持对象模式 tag-popup也需要改
      // 数组，对象模式
      // type: [Array, Object],
      type: Array,
      default: () => [
        // { val: '结算完成', key: 1 },
        // { val: '部分收款', key: 2 },
      ],
      // 对象模式
      // {
      //   key: {value: '标题',
      //   child: [{},{}]}
      // }
    },
    single: {
      // 单选-多选
      type: Boolean,
      default: false,
    },
    value: {
      // [key1,key2]
      // v-model
      type: Array,
      default: () => [],
    },
    disabledKeys: {
      // 禁止选中的key
      type: Array,
      default: () => [],
    },
    operable: {
      // 是否可操作的，false为仅展示
      type: Boolean,
      default: true,
    },
    curObjs: {
      // 需要待修饰符，传 空数组 进来即可，只是用来接收数据
      type: Array,
      default: () => [],
    },
    classify: Object, // {key,label} 根据哪个key进行分类，用label展示
  },
  data() {
    return {
      curObj: undefined, // 选中值的对象
      classifyObj: {}, // 分类对象 {key: {label: XX, child: []}
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.dataDeal();
      },
    },
    opts: {
      deep: true,
      handler() {
        this.dataDeal();
      },
    },
  },
  computed: {
    newIsCheckAll() {
      return this.value.length && this.value.length == this.opts.length;
    },
  },
  mounted() {
    // 分类处理
    if (this.classify) {
      this.opts.forEach((item) => {
        if (this.classifyObj[item[this.classify['key']]] == undefined) {
          this.classifyObj[item[this.classify['key']]] = {
            key: item[this.classify['key']],
            label: item[this.classify['label']],
            child: [item],
          };
        } else {
          this.classifyObj[item[this.classify['key']]]['child'].push(item);
        }
      });
      this.classifyObj = JSON.parse(JSON.stringify(this.classifyObj));
    }
    this.dataDeal();
  },
  methods: {
    dataDeal() {
      if (this.classify) {
        for (let key in this.classifyObj) {
          this.classifyObj[key].isAll = this.classifyObj[key].child.every(
            (item) => this.value.includes(item[this.keyName])
          );
        }
      }
      this.curObjDeal();
    },
    curObjDeal() {
      this.curObj = [];

      if (Array.isArray(this.opts)) {
        this.value.forEach((item) => {
          this.curObj.push(getKeyObj(item, this.opts, this.keyName));
        });
      }

      this.$emit('update:curObjs', this.curObj);
    },
    chose(item) {
      if (!this.operable) return;
      if (this.disabledKeys.indexOf(item[this.keyName]) !== -1) {
        return;
      }
      if (this.single) {
        // 单选
        if (this.value.indexOf(item[this.keyName]) !== -1) {
          this.curObj = [];
          this.$emit('input', []);
          this.$emit('change', {});
        } else {
          this.curObj = [item];
          this.$emit('input', [item[this.keyName]]);
          this.$emit('change', { key: [item[this.keyName]], obj: this.curObj });
        }
      } else {
        // 多选
        let index = this.value.indexOf(item[this.keyName]);
        let value = JSON.parse(JSON.stringify(this.value));
        if (index !== -1) {
          this.curObj.splice(index, 1);
          value.splice(index, 1);
        } else {
          this.curObj.push(item);
          value.push(item[this.keyName]);
        }
        this.$emit('input', value);
        this.$emit('change', { key: value, obj: this.curObj });
      }
    },
    choseAll(key) {
      if (this.classifyObj[key].isAll) {
        // 取消全选
        this.classifyObj[key].isAll = false;
        let value = JSON.parse(JSON.stringify(this.value));
        this.classifyObj[key].child.forEach((item) => {
          let index = value.indexOf(item[this.keyName]);
          if (index !== -1) {
            this.curObj.splice(index, 1);
            value.splice(index, 1);
          }
        });

        this.$emit('input', value);
        this.$emit('change', value, { key: value, obj: this.curObj });
      } else {
        // 全选
        this.classifyObj[key].isAll = true;
        let value = JSON.parse(JSON.stringify(this.value));
        this.classifyObj[key].child.forEach((item) => {
          if (!this.disabledKeys.includes(item[this.keyName])) {
            let index = value.indexOf(item[this.keyName]);
            if (index == -1) {
              this.curObj.push(item);
              value.push(item[this.keyName]);
            }
          }
        });

        this.$emit('input', value);
        this.$emit('change', { key: value, obj: this.curObj });
      }
    },
    background(key) {
      if (this.disabledKeys.indexOf(key) !== -1) {
        return 'grey';
      }
      if (this.value.indexOf(key) !== -1) {
        return `rgba(${this.$mainRgb}, 0.1)`;
      } else {
        // return "#f7f8fa";
        return 'rgba(82, 98, 124, 0.08)';
      }
    },
    color(key) {
      if (this.disabledKeys.indexOf(key) !== -1) {
        return 'white';
      }
      if (this.value.indexOf(key) !== -1) {
        // return "#fff";
        return this.$mainColor;
      } else {
        // return "#545454";
        return '#52627C';
      }
    },
    newCheckAllClick() {
      if (this.newIsCheckAll) {
        // 取消全选
        this.$emit('input', []);
        this.$emit('change', { key: [], obj: [] });
      } else {
        // 全选
        this.curObj = JSON.parse(JSON.stringify(this.opts));
        let value = [];
        this.curObj.forEach((e) => {
          value.push(e[this.keyName]);
        });
        this.$emit('input', value);
        this.$emit('change', { key: value, obj: this.curObj });
      }
    },
  },
};
</script>

<style lang="less">
.yiyu-select-tag {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;

  .tag {
    border-radius: 8px;
    display: inline-block;
    margin: 0.5rem;
    padding: 0.62rem 1rem;
    white-space: nowrap;
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 500;
  }

  .row {
    display: flex;
    justify-content: space-between;
    .left {
      color: @titleColor;
      font-size: 1rem;
      font-weight: 600;
    }
    .right {
      color: @mainColor;
    }
  }

  .classify-op-wrap {
    flex-basis: 100%;
  }
}
</style>
