<template>
  <div>
    <yy-navbar tab-title="每日投喂信息"></yy-navbar>
    <div class="main-content relative" v-myLoad="isLoading">
      <div class="row-between row-align-center">
        <div class="bold-title">
          {{ date && date.slice(0, 10) }}投喂({{ list.length }})
        </div>
        <div @click="exportData">
          <van-icon
            name="upgrade"
            class="change-table-icon"
            :color="$mainColor"
          />
          <span class="change-table-txt main-c">导出</span>
        </div>
      </div>
      <div class="content" v-for="(item, index) of list" :key="item.date">
        <div class="title">
          <div class="main-c" @click="toPage('record', item)">
            第{{ sectionToChinese(list.length - index) }}次
          </div>
          <div>{{ item.date && item.date.slice(10, 16) }}</div>
        </div>
        <div class="single-ele-table">
          <el-table
            :data="item.list"
            row-key="p_name"
            :tree-props="{ children: 'list', hasChildren: 'hasChildren' }"
            :expand-row-keys="item.expand"
          >
            <el-table-column
              v-for="item1 in item.title"
              :key="item1.prop"
              :prop="item1.prop"
              :label="item1.label"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import exportExcel from '@/utils/exportExcel';
import { deepCopy, sectionToChinese } from '@/utils/utils';
export default {
  name: 'dailyFeed',
  data() {
    return {
      isLoading: false,
      list: [],
      formDate: undefined,
      ss_key: undefined,
      lm_id: undefined,
      date: undefined,
    };
  },
  created() {
    if (this.$route.query) {
      let query = this.$route.query;
      this.ss_key = query.ss_key;
      this.lm_id = query.lm_id;
      this.date = query.date;
      this.getData();
    }
  },
  methods: {
    // 接口文档 https://www.teambition.com/project/60ffa2cbbee0dfac6d095c56/app/5eba5fba6a92214d420a3219/workspaces/60ffa2e8ac41f90046f82eed/docs/61163da8eaa119000164cef1
    getData() {
      this.isLoading = true;
      this.$get('/report/getDayFeeding', {
        ss_key: this.ss_key,
        lm_id: this.lm_id,
        date: this.date,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          res.data.list.forEach((item, index) => {
            item.expand = [];

            if (index == 0) {
              item.expand = ['合计'];
            }
            item.title.forEach((item1) => {
              if (item1.title == '池号') {
                item1.label = item1.title;
                item1.prop = 'p_name';
              } else {
                let str = '';
                if (item1.unit) {
                  // str = '/' + item.unit
                  str = `(${item1.unit})`;
                }

                item1.label = item1.title + str;
                item1.prop = item1.tt_key + '_' + item1.sum_type;
              }
            });
          });

          this.list = res.data.list;
        }
      });
    },
    sectionToChinese(num) {
      return sectionToChinese(num);
    },
    // FIXME:导出抄这(多个sheet表)
    exportData() {
      let sheetDatas = [];

      let list = deepCopy(this.list);

      list.forEach((item) => {
        let data = item.list;

        item.list.forEach((item1) => {
          if (Array.isArray(item1.list)) {
            data.push(...item1.list);
          }
        });

        sheetDatas.push({
          title: item.date && item.date.slice(10, 16),
          header: item.title,
          data,
        });
      });

      console.log({ fileName: this.date, sheetDatas });
      exportExcel({ fileName: this.date, sheetDatas });
    },
    toPage(type, item) {
      switch (type) {
        case 'record':
          this.$toPage('techDetail', {
            r_key: item.r_key,
            ws_type: this.ss_key ? 2 : 1,
            test_type: 2,
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;
}

.bold-title {
  font-size: 1rem;
  color: @titleColor;
  font-weight: 700;
}
.download {
  text-align: right;
  margin: 0.5rem 0.5rem;
}
.title {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-top: 0.8rem;
  font-weight: bold;
}
</style>
