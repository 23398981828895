// https://www.teambition.com/project/60ffa2cbbee0dfac6d095c56/app/5eba5fba6a92214d420a3219/workspaces/60ffa2e8ac41f90046f82eed/docs/610b4d6eeaa119000105dfc1
import { ArrayToString } from '@/utils/array'
import dayjs from 'dayjs'

const SetAllRow = (arr) =>
  arr.map((item) => {
    item.isRow = true
    return item
  })

// {
// "male_surplus":"公虾数",
// "female_surplus":"母虾数",
// "end_date":"淘汰日期",
// "del_remark":"备注",
// }
const Type10GridBoxAdaptor = (data) => {
  return SetAllRow([
    {
      label: '淘汰时间',
      value: data?.end_date,
    },
    {
      label: '淘汰母虾',
      value: data?.female_surplus,
    },
    {
      label: '淘汰公虾',
      value: data?.male_surplus,
    },
    {
      label: '备注',
      value: data?.del_remark,
    },
  ])
}

// {
//   "launch": "投放量",
//   "mc_list": [ // 基地
//     {
//       "mc_key": "基地key",
//       "mc_name": "基地名"
//     }
//   ],
//   "ws_list": [ // 车间
//     {
//       "ws_key": "车间key",
//       "ws_name": "车间名"
//     }
//   ],
//   "launch_date": "投放日期",
//   "record_date": "归属日期"
// }

const Type11GridBoxAdaptor = (data) => {
  const { ws_list } = data
  const basementName = ArrayToString(data.mc_list.map((mc) => mc.mc_name))

  let record = ''
  if (Array.isArray(ws_list) && ws_list.length) {
    record = `${
      data?.record_date ? dayjs(data?.record_date).format('YYYY-MM-DD') : ''
    } ${ws_list[0].ws_name}`
  }

  return SetAllRow([
    {
      label: '投放时间',
      value: data?.launch_date,
    },
    {
      label: '幼体归属',
      value: record,
    },
    {
      label: '幼体投放',
      value: data?.launch ? data?.launch + '万' : '',
    },
    {
      label: '投放基地',
      value: basementName,
    },
  ])
}

// {
//   "record_date": "归属日期",
//   "ws_list": [ // 车间
//     {
//       "ws_key": "车间key",
//       "ws_name": "车间名"
//     }
//   ],
//   "count": "排幼体量",
//   "date": "排幼体日期"
// }
const Type12GridBoxAdaptor = (data) =>
  SetAllRow([
    {
      label: '排放时间',
      value: data?.date,
    },
    {
      label: '幼体归属',
      value: data?.record_date,
    },
    {
      label: '排幼体数',
      value: data?.count,
      unit: '万',
    },
  ])

const SSLogGridBoxAdaptor = {
  10: Type10GridBoxAdaptor,
  11: Type11GridBoxAdaptor,
  12: Type12GridBoxAdaptor,
}

export const SSLogToGridBox = (type, data) => {
  if (typeof data == 'undefined') return []

  const adaptor = SSLogGridBoxAdaptor[type]
  if (adaptor) return adaptor(data)
  else return []
}
