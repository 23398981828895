import { render, staticRenderFns } from "./BatchDailyDetail.vue?vue&type=template&id=0ff7bff3&scoped=true&"
import script from "./BatchDailyDetail.vue?vue&type=script&lang=js&"
export * from "./BatchDailyDetail.vue?vue&type=script&lang=js&"
import style0 from "./BatchDailyDetail.vue?vue&type=style&index=0&id=0ff7bff3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff7bff3",
  null
  
)

export default component.exports