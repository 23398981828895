<template>
  <!-- 气泡弹窗 -->
  <!-- 更多操作 -->
  <van-popover
    :placement="placement"
    v-model="handleShow"
    theme="dark"
    trigger="click"
    :actions="actions"
    @select="moreSelect"
    get-container="app"
  >
    <!-- 触发 Popover 显示的元素内容 -->
    <template #reference>
      <!-- 在移动端才能实现完美交互 -->
      <slot v-if="$slots.default"></slot>
      <three-dot v-else></three-dot>
    </template>
  </van-popover>
</template>

<script>
import threeDot from '@/components/show/threeDot'
export default {
  name: 'handlePopver',
  components: {
    threeDot,
  },
  props: {
    actions: {
      type: Array,
      default: () => [
        { text: '编辑', fn: 'edit' },
        { text: '删除', fn: 'del' },
      ],
    },
    placement: {
      type: String,
      default: 'left-start',
    },
  },
  data() {
    return {
      handleShow: false,
    }
  },
  methods: {
    moreSelect(action) {
      this.handleShow = false

      const { fn } = action
      if (fn) {
        if (typeof fn == 'function') fn(action)
        else this.$emit(fn)
      }

      this.$emit('moreSelect', action)
    },
  },
}
</script>
