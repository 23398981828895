<template>
  <div class="rounded-md p-2">
    <div class="flex items-center">
      <div class="font-bold text-lg">{{ title }}</div>

      <slot name="title-slot" />

      <div class="ml-auto">
        <slot v-if="$slots['title-right-slot']" name="title-right-slot" />
        <div v-else-if="actions.length > 0">
          <HandlePopover :actions="actions" placement="left" />
        </div>
      </div>
    </div>

    <!-- card body -->
    <slot />
  </div>
</template>

<script>
import HandlePopover from '@/components/feedback/handlePopver.vue'

export default {
  name: 'YCard',
  components: {
    HandlePopover,
  },
  props: {
    title: {
      type: String,
      default: '卡片标题',
    },
    // example
    // {
    //   text: '删除',
    //   fn: () => {
    //     console.log('object')
    //   },
    // }
    actions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
