// 参考代码：https://frontzhm.github.io/web-demo/export-excel/
import XLSX from 'xlsx';

// 将workbook装化成blob对象
async function workbook2blob(workbook) {
  // 生成excel的配置项
  var wopts = {
    // 要生成的文件类型
    bookType: 'xlsx',
    // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    type: 'binary',
  };
  var wbout;
  // await import(/* webpackChunkName: "xlsx" */ 'xlsx').then((XLSX) => {
  wbout = XLSX.write(workbook, wopts);
  // })

  // 将字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  var blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  });
  return blob;
}

// 将blob对象创建bloburl，然后用a标签实现弹出下载框
function openDownloadDialog(blob, fileName) {
  if (typeof blob == 'object' && blob instanceof Blob) {
    blob = URL.createObjectURL(blob); // 创建blob地址
  }
  var aLink = document.createElement('a');
  aLink.href = blob;
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || '';
  var event;
  if (window.MouseEvent) event = new MouseEvent('click');
  //   移动端
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  }
  aLink.dispatchEvent(event);
}

/**
 * 支持导出多个sheet表
 * @param {*} param0
 */
export default async function exportExcel({
  fileName = '文件名',
  sheetDatas = [
    {
      title: 'sheet名',
      header: [
        // 支持对象格式 {department:'标题',count:'数量'}
        { label: '标题', prop: 'department' },
        { label: '数量', prop: 'count' },
      ],
      data: [
        { department: '行政部', count: 2 },
        { department: '前端部', count: 2, lll: '不需要展示的数据' },
      ],
    },
  ],
} = {}) {
  /* create a new blank workbook */

  sheetDatas = JSON.parse(JSON.stringify(sheetDatas));
  // let XLSX
  // // await import(/* webpackChunkName: "xlsx" */ 'xlsx').then(async (xlsx) => {
  // XLSX = xlsx
  let wb = XLSX.utils.book_new();
  sheetDatas.forEach((item) => {
    if (Array.isArray(item.header)) {
      let obj = {};

      item.header.forEach((item1) => {
        obj[item1.prop] = item1.label;
      });

      item.header = obj;
    }

    let list = [];
    // list: [
    //   // 这是该方法会转换成该数据结构，不用传
    //   { 标题: '行政部', 数量: 2 },
    //   { 标题: '前端部', 数量: 2 },
    // ],

    if (Array.isArray(item.data)) {
      item.data.forEach((item1) => {
        let obj = {};
        for (let key in item1) {
          if (item.header[key]) {
            obj[item.header[key]] = item1[key];
          }
        }

        if (Object.keys(obj).length) {
          list.push(obj);
        }
      });
    }

    let sheet = XLSX.utils.json_to_sheet(list);
    XLSX.utils.book_append_sheet(wb, sheet, item.title);
  });

  const workbookBlob = await workbook2blob(wb);
  openDownloadDialog(workbookBlob, fileName + `.xlsx`);
  // })
}
