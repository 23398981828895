var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yiyu-select-tag"},[(_vm.classify)?_vm._l((_vm.classifyObj),function(item,key,index){return _c('div',{key:index,staticClass:"classify-op-wrap"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(item.label))]),(!_vm.single)?[_c('div',{staticClass:"right",on:{"click":function($event){return _vm.choseAll(key)}}},[_vm._v(" "+_vm._s(item.isAll ? '取消全选' : '全选')+" ")])]:_vm._e()],2),_vm._l((item.child),function(item1,index1){return _c('span',{key:index1,staticClass:"tag",style:({
          background: _vm.background(item1[_vm.keyName]),
          color: _vm.color(item1[_vm.keyName]),
        }),on:{"click":function($event){return _vm.chose(item1)}}},[_vm._v(_vm._s(_vm.addValueKey ? item1[_vm.valueKey] + '_' + item1[_vm.addValueKey] : item1[_vm.valueKey]))])})],2)}):[(!_vm.single)?_c('span',{staticClass:"tag",style:({
        background: _vm.newIsCheckAll ? '#fff1ea' : '#f1f3f5',
        color: _vm.newIsCheckAll ? 'rgb(255, 117, 46)' : 'rgb(82, 98, 124)',
      }),on:{"click":_vm.newCheckAllClick}},[_vm._v("不限")]):_vm._e(),_vm._l((_vm.opts),function(item,index){return _c('span',{key:index,staticClass:"tag",style:({
        background: _vm.background(item[_vm.keyName]),
        color: _vm.color(item[_vm.keyName]),
      }),on:{"click":function($event){return _vm.chose(item)}}},[_vm._v(_vm._s(_vm.addValueKey ? item[_vm.valueKey] + '_' + item[_vm.addValueKey] : item[_vm.valueKey]))])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }