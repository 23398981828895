<template>
  <div class="pb-20">
    <yy-navbar :tab-title="title">
      <template #right>
        <div
          class="main-c"
          @click="toPage"
          v-if="IS_HANDLE('SEED_BATCH_LARVA_ADD')"
        >
          育苗安排
        </div>
      </template>
    </yy-navbar>
    <div class="row-between p-2">
      <div></div>
      <download-excel
        :data="exportData"
        :fields="exportHeader"
        :name="exportFileName"
      >
        <van-icon name="upgrade" class="change-table-icon main-c" />
        <span class="change-table-txt main-c">导出</span>
      </download-excel>
    </div>

    <div class="mx-4 mb-2">
      <div class="mb-1">
        <span class="font-bold">孵化日期</span>
        <calendar-select-range
          :min-date="minDateMixin"
          :max-date="maxDateMixin"
          :start.sync="calendarSelectRange.start"
          :end.sync="calendarSelectRange.end"
          @change="handleSearch"
        />
      </div>

      <span class="font-bold">聚合</span>
      <ScrollTag
        class="flex"
        :opts="scrollTags.opts"
        :chosed.sync="checked"
        :single="true"
      />
      <ScrollTag
        class="flex"
        :opts="scrollTags2.opts"
        :chosed.sync="checked2"
        :single="true"
      />
    </div>
    <div class="search-area">
      <van-search v-model="keyword" placeholder="请输入搜索关键词" />
      <!-- @input="getData" -->
      <van-button
        class="search-btn lhj-watch-search"
        type="primary"
        plain
        @click="handleSearch"
        size="small"
        >确认查询</van-button
      >
    </div>
    <el-table
      :data="list"
      @cell-click="handleCellClickMixin"
      :cell-style="cellStyleMixin"
      @row-click="showTip"
      v-myLoad="isLoading"
    >
      <el-table-column
        v-for="prop in tableProps"
        :key="prop"
        :prop="prop"
        :label="wordMap[prop]"
        v-bind="tablePropsConfig[prop]"
      />
      <!-- <template slot-scope="scope">
        <template>{{ scope.row[prop] }}</template>
      </template> -->
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue';

import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);
import { YDialog } from 'yiyu-vant-ui';
import ElTableMixin from '@/mixin/ElTableMixin';
import useAggregations from '@/use/useAggregations';
import ScrollTag from '@/components/tag/scrollTag.vue';
import CalendarSelectRange from '@/components/calendar/calendarSelectRange.vue';
import { VanCalendarPresetMixin } from '@/mixin/VanCalendarPreset';
import { compact, isUndefined, omitBy } from 'lodash-es';
import store from '@/store';
import { ArrayToString } from '@/utils/array';
import { arrToObj } from '@/utils/utils';

export default {
  name: 'sArrangeList',
  components: {
    CalendarSelectRange,
    ScrollTag,
  },
  mixins: [ElTableMixin, VanCalendarPresetMixin],
  data() {
    return {
      keyword: '',
      title: '幼体管理',
      wordMap: {
        date: '日期',
        record_date: '孵化日期',
        batch: '种虾批次',
        real_yield: '实际产量',
        no_arrange: '待安排量',
        arrange: '已安排量',
        mc_list: '幼体去向',
        lc_count: '处理幼体量',
        ss_ws_list: '种虾养殖地点',
        remark: '备注',
        l_mc_name: '去向基地',
        l_ws_name: '去向车间',
        launch: '幼体投放量',
        batch_list: '育苗批次',
      },
      list: [],
      page: 1,
      count: 20,
      total: 0,
      isLoading: false,
      scroll: 0,
      calendarSelectRange: {
        start: this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        end: this.$dayjs().add(3, 'day').format('YYYY-MM-DD'),
      },
      scrollTags: {
        checked: undefined,
        opts: [
          {
            label: '日',
            name: 'day',
          },
          {
            label: '月',
            name: 'month',
          },
          {
            label: '年',
            name: 'year',
          },
        ],
      },
      scrollTags2: {
        checked2: undefined,
        opts: [
          {
            label: '基地',
            name: 'mc',
          },
          {
            label: '车间',
            name: 'ws',
          },
          {
            label: '批次',
            name: 'batch',
          },
        ],
      },
      checked: undefined,
      checked2: undefined,
    };
  },

  activated() {
    const { ss_key, ss_batch, start_at, end_at } = this.$route.query;

    if (ss_batch) this.title = ss_batch;
    else this.title = '幼体管理';

    if (start_at && end_at) {
      this.calendarSelectRange = {
        start: this.$dayjs(start_at).format('YYYY-MM-DD'),
        end: this.$dayjs(end_at).format('YYYY-MM-DD'),
      };
    }

    this.getData(omitBy({ ss_key }, isUndefined));
  },
  computed: {
    tablePropsConfig() {
      return omitBy(
        {
          record_date: {
            width: '120',
            formatter: (row, column, cellValue) => {
              if (row.is_sum_item) {
                return '合计';
              }
              if (this.scrollTags.checked == 'year') {
                return this.$dayjs(cellValue).format('YYYY');
              } else if (this.scrollTags.checked == 'month') {
                return this.$dayjs(cellValue).format('YYYY-MM');
              }

              return this.$dayjs(cellValue).format('YYYY-MM-DD');
            },
            hasPermission:
              (this.scrollTags2.checked2 && this.scrollTags.checked) ||
              !this.scrollTags2.checked2,
          },
          // 种虾批次
          batch: {
            width: '200',
            cellStyle: {
              color: this.$mainColor,
            },
            formatter: (row, column, cellValue) => {
              if (
                row.is_sum_item &&
                this.scrollTags2.checked2 == 'batch' &&
                !this.scrollTags.checked
              ) {
                return '合计';
              }
              if (row.batch_list) {
                return (
                  row?.batch_list?.map((item) => item.batch).join('、') || '-'
                );
              } else {
                return cellValue || '-';
              }
            },

            onClick: (e) => {
              if (!e.batch_list) return;
              let data = e.batch_list?.map((item) => {
                return {
                  key: item.batch,
                  onConfirm: () => {
                    this.$toPage(this, 'sBatchDetail', { ss_key: item.ss_key });
                  },
                };
              });
              YDialog({
                type: 'list',
                title: '请选择要跳转的批次详情',
                propsData: {
                  showCancelButton: true,
                },
                data,
              });
            },
            hasPermission: !this.showItem,

            // shoWhen: [],
          },
          ss_ws_list: {
            width: 300,
            showOverflowTooltip: true,
            formatter: (row, column, cellValue) => {
              if (Array.isArray(cellValue)) {
                const nameArr = compact(
                  cellValue?.map((val) => val.mc_name + '(' + val.ws_name + ')')
                );
                return ArrayToString(nameArr) || '-';
              } else {
                return '-';
              }
            },
            hasPermission: !this.scrollTags2.checked2,
          },
          real_yield: {
            width: 100,
            hasPermission: !this.showItem,
          },
          no_arrange: {
            width: 100,
            hasPermission: !this.showItem,
          },
          arrange: {
            width: 100,
            hasPermission: !this.showItem,
          },
          mc_list: {
            width: 300,

            cellStyle: {
              color: this.scrollTags2.checked2 ? '#000000' : this.$mainColor,
            },
            formatter: (row, column, cellValue) => {
              if (!Array.isArray(cellValue)) cellValue = [];
              let data = [];

              cellValue?.map((item) => {
                let ws_name = item.ws_list
                  .map((item1) => {
                    return item1.ws_name;
                  })
                  .join('、');

                data.push(
                  `${item.mc_name}${ws_name && '-' + ws_name}(${
                    item.launch ?? 0
                  })`
                );
              });

              // let str = cellValue.reduce((str, cur) => {
              //   const { mc_name, launch } = cur;
              //   if (mc_name && launch)
              //     return `${str}${cur.mc_name}(${cur.launch ?? 0})`;
              //   else return '';
              // }, '');

              return data.join('、') || '-';
            },
            hasPermission: !this.showItem,
            onClick: (e) => {
              // if (!this.scrollTags2.checked2) return
              console.log('mc', e);
              const { mc_list } = e;

              if (mc_list.length == 0) return;
              let data = [];
              mc_list?.map((item) => {
                let ws_name = item.ws_list
                  .map((item1) => {
                    return item1.ws_name;
                  })
                  .join('、');

                data.push({
                  key: `${item.mc_name}${ws_name && '-' + ws_name}(${
                    item.launch ?? 0
                  })`,
                  onConfirm: () => {
                    this.$toPage('larvaProduct', item);
                  },
                });
              });

              YDialog({
                type: 'list',
                title: '请选择要跳转的批次详情',
                propsData: {
                  showCancelButton: true,
                },
                data,
              });
            },
          },
          lc_count: {
            width: 100,

            cellStyle: {
              color: this.scrollTags2.checked2 ? '#000000' : this.$mainColor,
            },
            formatter: (row, column, cellValue) => cellValue ?? '-',

            hasPermission:
              store.getters.IS_HANDLE('SEED_LARVA_CLEAR_SEE') && !this.showItem,
            onClick: (e) => {
              const { sr_key } = e;
              if (!sr_key) return;
              this.$router.push(`/larva/clear/${sr_key}`);
            },
          },

          l_ws_name: {
            width: 100,
            formatter: (row, column, cellValue) => {
              if (
                row.is_sum_item &&
                this.scrollTags2.checked2 == 'ws' &&
                !this.scrollTags.checked
              ) {
                return '合计';
              }
              return cellValue || '-';
            },
            hasPermission: this.scrollTags2?.checked2 == 'ws',
          },

          l_mc_name: {
            width: 100,
            formatter: (row, column, cellValue) => {
              if (
                row.is_sum_item &&
                this.scrollTags2.checked2 == 'mc' &&
                !this.scrollTags.checked
              ) {
                return '合计';
              }
              return cellValue || '-';
            },
            hasPermission:
              this.scrollTags2?.checked2 == 'mc' ||
              this.scrollTags2?.checked2 == 'ws',
          },
          batch_list: {
            width: 100,
            formatter: (row, column, cellValue) =>
              cellValue?.length + '批次' ?? '暂无',
            hasPermission: this.scrollTags2?.checked2 == 'mc',
          },
          launch: {
            width: 100,
            formatter: (row, column, cellValue) => cellValue ?? '暂无',
            hasPermission:
              this.scrollTags2?.checked2 == 'mc' ||
              this.scrollTags2?.checked2 == 'ws',
          },

          remark: {
            width: 100,
            hasPermission: !this.showItem,
          },
        },
        ({ hasPermission }) => {
          if (typeof hasPermission == 'boolean') return !hasPermission;
        }
      );
    },
    showItem() {
      console.log(
        this.scrollTags2?.checked2 == 'mc' || this.scrollTags2?.checked2 == 'ws'
      );
      return (
        this.scrollTags2?.checked2 == 'mc' || this.scrollTags2?.checked2 == 'ws'
      );
    },
    exportData() {
      let list = JSON.parse(JSON.stringify(this.list));
      list.forEach((item) => {
        if (Array.isArray(item.mc_list)) {
          if (item.mc_list.length == 0) {
            item.mc_list = '';
          } else {
            let data = [];

            item.mc_list?.map((item1) => {
              let ws_name = item1.ws_list
                ?.map((item2) => {
                  return item2.ws_name;
                })
                .join('、');

              data.push(
                `${item1.mc_name}${ws_name && '-' + ws_name}(${
                  item1.launch ?? 0
                })`
              );
            });

            item.mc_list = data.join('、');
          }
        }

        if (Array.isArray(item.ss_ws_list)) {
          console.log(item.ss_ws_list);
          if (item.ss_ws_list.length == 0) {
            item.ss_ws_list = '';
          } else {
            item.ss_ws_list = item?.ss_ws_list?.map((item1) => item1.ws_name);
            item.ss_ws_list = item.ss_ws_list.toString();
          }
        }
      });
      return list;
    },
    exportHeader() {
      let obj = {};

      this.tableProps.forEach((item) => {
        obj[this.wordMap[item]] = item;
      });

      return obj;
    },
    exportFileName() {
      return '种虾产量列表';
    },
    tableProps({ tablePropsConfig, scrollTags }) {
      return useAggregations(tablePropsConfig)(
        scrollTags.checked ? [scrollTags.checked] : []
      );
    },
  },
  methods: {
    showTip(e, c) {
      const { sr_key, ss_key, batch } = e;

      if (!sr_key && !ss_key) return;
      if (
        c.label == '幼体去向' ||
        c.label == '处理幼体量' ||
        this.scrollTags.checked ||
        this.scrollTags2.checked2
      ) {
        return;
      }
      YDialog({
        type: 'list',
        title: '请选择要操作内容',
        propsData: {
          showCancelButton: true,
        },
        data: [
          {
            key: '安排幼苗',
            onConfirm: () => {
              this.$toPage(this, 'larvaLaunch', {
                status: 'add',
                ss_key,
                sr_key,
                alias: batch,
              });
            },
            hide: !this.IS_HANDLE('SEED_BATCH_SCHEDULE'),
          },
          {
            key: '排幼苗',
            onConfirm: () => {
              this.$router.push(`/larva/clear/${sr_key}`);
            },
            hide: !this.IS_HANDLE('SEED_LARVA_CLEAR'),
          },
          {
            key: '跳转种虾批次详情',
            onConfirm: () => {
              if (!ss_key) return;
              this.$toPage(this, 'sBatchDetail', {
                ss_key,
              });
            },
          },
        ].filter((data) => !data.hide),
      });
    },
    handleSearch() {
      this.scrollTags.checked = this.checked;
      this.scrollTags2.checked2 = this.checked2;
      this.getData({
        groupBy: this.checked,
        groupBy2: this.checked2,
      });
    },
    // handleAggregations() {
    //   const { checked } = this.scrollTags;
    //   const { checked2 } = this.scrollTags2;
    //   this.getData({
    //     groupBy: checked,
    //     groupBy2: checked2,
    //   });
    //   // checked
    //   //   ? this.getData({
    //   //       groupBy: checked,
    //   //     })
    //   //   : this.getData()
    // },
    getData(params) {
      this.isLoading = true;
      const { start, end } = this.calendarSelectRange;
      return this.$get('/settle/getSRArrange', {
        ...params,
        keyword: this.keyword,
        date_range: { start, end },
      })
        .then((res) => {
          const { code, data } = res;
          if (code == 10000) {
            this.isLoading = false;
            this.list = data.list;
            return data;
          }
          return {};
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    toPage() {
      this.$toPage(this, 'larvaLaunch', {
        status: 'add',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .van-search {
    flex-basis: 100%;
  }

  .search-btn {
    white-space: nowrap;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}
</style>
