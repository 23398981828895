<template>
  <div class="main">
    <yiyu-navbar tabTitle="每日数据记录"></yiyu-navbar>
    <div class="bold-title">{{ form.name }}</div>
    <data-field :dataObj="getDataObj('all', topData)"></data-field>

    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
    >
      <template #divider>
        <div class="grey-bg">
          <van-divider>&nbsp;</van-divider>
        </div>
      </template>
      <template #isPool>
        <van-checkbox
          v-model="isPool"
          shape="square"
          class="pool-handle"
          v-if="male_list.length != 0 || female_list.length != 0"
          >精准到池</van-checkbox
        >
      </template>
      <template #singleMale>
        <data-field
          :dataObj="getDataObj('male', form)"
          v-if="!isPool"
        ></data-field>
      </template>
      <template #singleFemale>
        <data-field
          :dataObj="getDataObj('female', form)"
          v-if="!isPool"
        ></data-field>
      </template>
      <template v-for="(item, index) in male_list">
        <template :slot="'male' + index">
          <data-field
            :key="index + 'male'"
            :dataObj="getDataObj('male', item)"
            v-if="isPool"
          ></data-field>
        </template>
      </template>
      <template v-for="(item, index) in female_list">
        <template :slot="'female' + index">
          <data-field
            :key="index + 'female'"
            :dataObj="getDataObj('female', item)"
            v-if="isPool"
          ></data-field>
        </template>
      </template>
    </yiyu-form>
    <fixed-btn
      :btnTxt="status == 'add' ? '确认新增' : '保存'"
      @click="toSubmit"
      :loading="btnLoading"
      :disabled="btnLoading"
    ></fixed-btn>
    <datetime-popup
      :date.sync="form.record_date"
      :show.sync="timeShow"
      title="选择归属日期"
      @confirm="changeRecordDate"
      @cancel="timeShow = false"
      type="month-day"
    >
    </datetime-popup>
    <datetime-popup
      :date="form.start_date"
      :show.sync="timeStartShow"
      title="选择开始孵化时间"
      @confirm="confirmPicker($event, 'startDate')"
      @cancel="timeStartShow = false"
      type="time"
    >
    </datetime-popup>
    <datetime-popup
      :date="form.end_date"
      :show.sync="timeEndShow"
      title="选择完成孵化时间"
      @confirm="confirmPicker($event, 'endDate')"
      @cancel="timeEndShow = false"
      type="datetime"
      :minDate="form.record_date ? new Date(form.record_date) : undefined"
    >
    </datetime-popup>
    <keep-alive>
      <b-person-picker
        ref="personPicker"
        :cur-key.sync="form.operator"
        :cur-name.sync="form.operator_value"
        keyName="uid"
        :role="[1, 7]"
        title="选择操作人员"
        :single="true"
      >
      </b-person-picker>
    </keep-alive>
    <tag-popup
      v-if="showPoolPicker"
      :curKeys="[curItem.mating_pool]"
      value-key="pool_name"
      keyName="sp_key"
      :show.sync="showPoolPicker"
      :opts="curPoolOpts"
      @confirmObj="confirmPicker($event, 'pool')"
      @cancel="showPoolPicker = false"
      :single="true"
      title="请选择"
    >
    </tag-popup>
  </div>
</template>

<script>
import tagPopup from '@/components/popup/tag'
import bPersonPicker from '@/bComponents/bPersonPicker'
import yiyuForm from '@/components/form/yiyuForm.vue'
import fixedBtn from '@/components/button/fixedBtn'
import yiyuNavbar from '@/components/navbar'
import dataField from '@/components/layout/dataField'
import datetimePopup from '@/components/popup/datetime.vue'
import { deepCopy, getKeyValue, numToStr } from '@/utils/utils'
export default {
  name: 'sDailyEdit',
  components: {
    yiyuForm,
    fixedBtn,
    yiyuNavbar,
    dataField,
    datetimePopup,
    bPersonPicker,
    tagPopup,
  },
  data() {
    return {
      activated: false, // mounted 和 activated 只须执行一个
      btnLoading: false,
      status: 'add',
      thisAlias: this,
      topData: {},
      form: {
        type: 'all', // mating为编辑交配信息、loss为损耗信息、hatch为孵化信息、all为全部信息
        batch: undefined,
        sr_key: undefined,
        ss_key: undefined,
        alias: undefined,
        record_date: undefined, //归属时间
        start_date: undefined, //开始孵化时间
        end_date: undefined, //孵化结束时间
        operator: undefined, //操作人
        operator_value: undefined, //操作人
        mating_suc: undefined, //交配成功数
        matrix_count: undefined, //产卵母虾
        egg_count: undefined, //产卵量
        e_yield: undefined, //预估产量
        fer_rate: undefined, //受精率
        hatch_rate: undefined, //孵化率
        unit_yield: undefined, // 预估单产
        real_yield: undefined, // 实际产量
        in_pool: 1, //是否精确到池   1为是 2 为否
        mating_count: undefined, //交配量
        male_loss: undefined, //公虾损耗
        mature_count: undefined, //成熟数量
        female_loss: undefined, //母虾损耗
        //若为精确到池
        pool_list: [],
      },
      male_list: [],
      female_list: [],
      isPool: true,
      timeShow: false,
      timeStartShow: false,
      timeEndShow: false,
      curItem: {},
      curType: undefined,
      curPoolOpts: [],
      showPoolPicker: false,
    }
  },
  computed: {
    maleOpts() {
      let male = []
      this.male_list.forEach((item, index) => {
        male.push({
          title: `${item.pool_name || '暂无车间池名'}`,
          show: this.isPool && this.form.type != 'hatch',
          child: [
            // {
            //   formType: 'select',
            //   label: '交配母虾池',
            //   value: item.mating_pool_value,
            //   name: 'mating_pool_value',
            //   valueKey: ['male_list', index, 'mating_pool_value'],
            //   show: this.form.type != 'loss',
            //   extra: '条',
            //   click() {
            //     _this.curItem = item
            //     _this.curType = 'male'
            //     _this.curPoolOpts = _this.female_list
            //     _this.showPoolPicker = true
            //   },
            // },
            {
              topSlot: 'male' + index,
              label: '交配量',
              value: this.male_list[index]['mating_count'],
              name: 'mating_count',
              valueKey: ['male_list', index, 'mating_count'],
              show: this.form.type != 'loss',
              extra: '条',
            },
            {
              label: '公虾损耗',
              value: item.male_loss,
              name: 'male_loss',
              valueKey: ['male_list', index, 'male_loss'],
              show: this.form.type != 'mating',
              extra: '条',
            },
          ],
        })
      })
      return male
    },
    femaleOpts() {
      let female = []
      // let _this = this
      this.female_list.forEach((item, index) => {
        female.push({
          title: `${item.pool_name || '暂无车间池名'}`,
          show: this.isPool && this.form.type != 'hatch',
          child: [
            // {
            //   formType: 'select',
            //   label: '交配公虾池',
            //   value: item.mating_pool_value,
            //   name: 'mating_pool_value',
            //   valueKey: ['female_list', index, 'mating_pool_value'],
            //   click() {
            //     _this.curItem = item
            //     _this.curType = 'female'
            //     _this.curPoolOpts = _this.male_list
            //     _this.showPoolPicker = true
            //   },
            //   show: this.form.type != 'loss',
            // },
            {
              topSlot: 'female' + index,
              label: '成熟量',
              value: item.mature_count,
              name: 'mature_count',
              type: 'number',
              valueKey: ['female_list', index, 'mature_count'],
              show: this.form.type != 'loss',
              extra: '条',
            },
            {
              label: '母虾损耗',
              value: item.female_loss,
              type: 'number',
              name: 'female_loss',
              valueKey: ['female_list', index, 'female_loss'],
              show: this.form.type != 'mating',
              extra: '条',
            },
          ],
        })
      })
      return female
    },
    formOpts() {
      let _this = this

      let base = [
        {
          title: '',
          child: [
            {
              label: '归属日期',
              required: true,
              formType: 'select',
              placeholder: '请选择表单归属日期',
              value:
                this.form.record_date && this.form.record_date.slice(0, 10),
              name: 'record_date',
              click() {
                _this.timeShow = true
              },
            },
            {
              label: '操作人',
              formType: 'select',
              value: this.form.operator_value,
              name: 'operator',
              click() {
                if (_this.$refs['personPicker']) {
                  _this.$refs['personPicker'].show = true
                }
              },
            },
          ],
        },
        {
          show: this.form.type != 'hatch',
          title:
            this.form.type == 'all'
              ? '交配及损耗'
              : this.form.type == 'loss'
              ? '损耗'
              : '交配',
          titleRight: 'isPool',
          child: [],
        },
        ...this.maleOpts,
        {
          topSlot: 'divider',
        },
        ...this.femaleOpts,
        {
          title: '',
          show: this.form.type != 'hatch',
          child: [
            // 自动计算 或 手写
            {
              label: '交配母虾',
              value: this.form.mating_count,
              name: 'mating_count',
              readonly: this.isPool,
              placeholder: this.isPool
                ? '自动计算(将所有公虾池的交配数量进行相加)'
                : undefined,
              extra: '条',
              show: this.form.type != 'loss',
            },
            {
              label: '成熟母虾',
              value: this.form.mature_count,
              name: 'mature_count',
              readonly: this.isPool,
              placeholder: this.isPool ? '自动计算' : undefined,
              extra: '条',
              show: this.form.type != 'loss',
            },
            {
              label: '损耗母虾',
              value: this.form.female_loss,
              name: 'female_loss',
              readonly: this.isPool,
              placeholder: this.isPool ? '自动计算' : undefined,
              extra: '条',
              show: this.form.type != 'mating',
            },
            {
              label: '损耗公虾',
              value: this.form.male_loss,
              name: 'male_loss',
              readonly: this.isPool,
              placeholder: this.isPool ? '自动计算' : undefined,
              extra: '条',
              show: this.form.type != 'mating',
            },
          ],
        },
        {
          // 孵化信息
          title: '孵化信息',
          show: this.form.type == 'all' || this.form.type == 'hatch',
          child: [
            // {
            //   label: '开始孵化时间',
            //   formType: 'select',
            //   value: this.getHourMin(this.form.start_date),
            //   name: 'start_date',
            //   click() {
            //     _this.timeStartShow = true
            //   },
            //   show: this.form.type == 'hatch' || this.form.type == 'all',
            // },
            // {
            //   label: '孵化完成时间',
            //   formType: 'select',
            //   value: this.form.end_date,
            //   name: 'end_date',
            //   click() {
            //     _this.timeEndShow = true
            //   },
            //   show: this.form.type == 'hatch' || this.form.type == 'all',
            // },
            {
              label: '受精率',
              extra: '%',
              value: this.form.fer_rate,
              name: 'fer_rate',
              type: 'number',
            },
            {
              label: '产卵母虾量',
              value: this.form.matrix_count,
              name: 'matrix_count',
              type: 'number',
              extra: '条',
            },
            {
              label: '交配率',
              placeholder: '自动计算(产卵母虾/成熟母虾)',
              value: this.autoMatingRate,
              readonly: true,
              extra: '%',
            },
            {
              label: '明日预估单产',
              value: this.form.unit_yield,
              name: 'unit_yield',
              type: 'number',
              extra: '万',
            },
            {
              label: '明日预估产量',
              placeholder: '自动计算(预估明日单产*产卵母虾)',
              value: this.autoEstiYield,
              type: 'number',
              readonly: true,
              extra: '万',
            },
            {
              label: '今日实际产量',
              value: this.form.real_yield,
              name: 'real_yield',
              type: 'number',
              extra: '万',
            },
            {
              label: '今日实际单产',
              placeholder: '自动计算(今日实际产量/昨日产卵母虾)',
              readonly: true,
              value: this.autoRealYield,
              extra: '万',
            },
            {
              label: '昨日预估单产',
              placeholder: '自动计算',
              readonly: true,
              value: this.form.l_unit_yield,
              extra: '万',
              show: false,
            },
            {
              label: '昨日预估产量',
              placeholder: '自动计算',
              readonly: true,
              value: this.form.l_e_yield,
              extra: '万',
              show: false,
            },
          ],
        },
      ]

      return base
    },
    hatchRate() {
      if (!Number(this.form.real_yield || 0)) {
        return undefined
      } else {
        return (
          ((
            (Number(this.form.real_yield || 0) / Number(this.form.egg_count)) *
            100
          ).toFixed(2) *
            100) /
          100
        )
      }
    },
    // 自动计算的数据，不用传给后端
    // 交配率
    autoMatingRate() {
      let { matrix_count, mature_count } = this.form
      if (matrix_count != undefined && mature_count) {
        return (((matrix_count / mature_count) * 100).toFixed(2) * 100) / 100
      } else {
        return undefined
      }
    },
    // 预估产量
    autoEstiYield() {
      let { unit_yield, matrix_count } = this.form
      if (unit_yield != undefined && matrix_count != undefined) {
        return unit_yield * matrix_count
      } else {
        return undefined
      }
    },
    autoRealYield() {
      let { real_yield, l_matrix_count } = this.form
      if (real_yield != undefined && l_matrix_count) {
        return ((real_yield / l_matrix_count).toFixed(2) * 100) / 100
      } else {
        return undefined
      }
    },
  },
  watch: {
    male_list: {
      deep: true,
      handler(val) {
        this.dataAutoDeal(val, 'male')
      },
    },
    female_list: {
      deep: true,
      handler(val) {
        this.dataAutoDeal(val, 'female')
      },
    },
    isPool() {
      this.dataAutoDeal(this.male_list, 'male')
      this.dataAutoDeal(this.female_list, 'female')
    },
  },
  activated() {
    if (!this.activated) {
      this.activated = true
      return
    }
  },
  mounted() {
    this.activated = false
    if (this.$route.query) {
      let query = this.$route.query
      this.form.sr_key = query.sr_key
      this.form.ss_key = query.ss_key
      this.form.type = query.type || 'all'
      if (this.form.sr_key) {
        this.status = 'edit'
      } else {
        this.status = 'add'
        this.form.operator_value = this.$store.state.basic.nickname
        this.form.operator = this.$store.state.basic.uid
      }

      this.getData()
    }
  },
  methods: {
    getData(type) {
      this.$get('/record/getEditData', {
        sr_key: this.form.sr_key,
        ss_key: this.form.ss_key,
        record_date: this.form.record_date,
      }).then((res) => {
        if (res.code == 10000) {
          if (type == 'dateChange') {
            let { type, ss_key } = this.form
            this.form = this.$options.data.call(this).form
            this.form.type = type
            this.form.ss_key = ss_key
          }
          let data = res.data

          data.end_date = data.pool_data.end_date || undefined
          data.start_date = data.pool_data.start_date || undefined
          data.in_pool = data.top_data.in_pool
          if (data.in_pool == 1) {
            this.isPool = true
          } else {
            this.isPool = false
          }
          data.operator = data.pool_data.operator
          data.operator_value = data.pool_data.operator_value
          data.record_date = data.pool_data.record_date

          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          this.form = data
          this.topData = data.top_data
          let key = [
            'pool_name',
            'mating_count',
            'male_loss',
            'mature_count',
            'female_loss',
            'mating_pool',
            'mating_pool_value',
          ]

          let male_list = data.pool_list.male_list || []
          let female_list = data.pool_list.female_list || []
          male_list.forEach((item) => {
            key.forEach((item1) => {
              item[item1] = item[item1] || undefined
            })
            if (item.mating_pool) {
              item.mating_pool_value = getKeyValue(
                item.mating_pool,
                female_list,
                'pool_name',
                'sp_key',
              )
            }
          })
          female_list.forEach((item) => {
            key.forEach((item1) => {
              item[item1] = item[item1] || undefined
            })
            if (item.mating_pool) {
              item.mating_pool_value = getKeyValue(
                item.mating_pool,
                male_list,
                'pool_name',
                'sp_key',
              )
            }
          })
          this.male_list = deepCopy(male_list)

          this.female_list = deepCopy(female_list)
        }
      })
    },
    onSubmit() {
      this.btnLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      if (this.isPool) {
        form.in_pool = '1'
      } else {
        form.in_pool = '2'
      }

      form.hatch_rate = this.hatchRate
      form.pool_list = {
        female_list: this.female_list,
        male_list: this.male_list,
      }

      form.e_yield = this.autoEstiYield
      form.actual_yield = this.autoRealYield
      form.mating_rate = this.autoMatingRate

      this.$post('/record/EditData', numToStr(form)).then((res) => {
        this.btnLoading = false
        if (res.code === 10000) {
          this.$toast.success('每日安排记录成功')
          this.$go(-1, this)
        }
      })
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    getDataObj(type, item = this.form) {
      switch (type) {
        case 'all':
          return [
            [
              {
                descText: '当前数量',
                data: `公:${item.male_surplus || 0},母:${
                  item.female_surplus || 0
                }`,
              },
              {
                descText: '育苗天数',
                data: item.l_date,
                unitText: '天',
              },
              {
                descText: '上次产量',
                data: item.yield_cnt,
                unitText: '万',
              },
            ],
          ]
        case 'male':
          return [
            [
              {
                descText: '',
                data: '公虾',
              },
              {
                descText: '当前数量',
                data: this.isPool ? item.current_count : item.m_current_count,
              },
              {
                descText: '上次交配',
                data: item.l_mating,
              },
              {
                descText: '上次损耗',
                data: this.isPool ? item.l_loss : item.m_l_loss,
              },
            ],
          ]
        case 'female':
          return [
            [
              {
                descText: '',
                data: '母虾',
              },
              {
                descText: '当前数量',
                data: this.isPool ? item.current_count : item.fm_current_count,
              },
              {
                descText: '上次成熟',
                data: item.l_mature,
              },
              {
                descText: '上次损耗',
                data: this.isPool ? item.l_loss : item.fm_l_loss,
              },
            ],
          ]
      }
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'pool':
          this.curItem.mating_pool = (e[0] && e[0].sp_key) || undefined
          this.curItem.mating_pool_value = (e[0] && e[0].pool_name) || undefined
          if (this.curType == 'male') {
            this.female_list?.forEach((item) => {
              if (item.mating_pool == this.curItem.sp_key) {
                item.mating_pool = undefined
                item.mating_pool_value = undefined
              }
              if (item.sp_key == this.curItem.mating_pool) {
                item.mating_pool = this.curItem.sp_key
                item.mating_pool_value = this.curItem.pool_name
              }
            })

            this.male_list?.forEach((item) => {
              if (item.sp_key != this.curItem.sp_key) {
                if (item.mating_pool == this.curItem.mating_pool) {
                  item.mating_pool = undefined
                  item.mating_pool_value = undefined
                }
              }
            })
          } else if (this.curType == 'female') {
            this.male_list?.forEach((item) => {
              if (item.mating_pool == this.curItem.sp_key) {
                item.mating_pool = undefined
                item.mating_pool_value = undefined
              }
              if (item.sp_key == this.curItem.mating_pool) {
                item.mating_pool = this.curItem.sp_key
                item.mating_pool_value = this.curItem.pool_name
              }
            })

            this.female_list?.forEach((item) => {
              if (item.sp_key != this.curItem.sp_key) {
                if (item.mating_pool == this.curItem.mating_pool) {
                  item.mating_pool = undefined
                  item.mating_pool_value = undefined
                }
              }
            })
          }
          this.showPoolPicker = false
          break
        case 'startDate':
          // this.form.start_date = e
          this.form.start_date =
            this.form.record_date.slice(0, 11) + ' ' + e + ':00'
          this.timeStartShow = false
          break
        case 'endDate':
          this.form.end_date = e
          // this.form.record_date.slice(0, 11) + ' ' + e + ':00'
          this.timeEndShow = false
          break
      }
    },
    changeRecordDate() {
      this.timeShow = false
      this.getData('dateChange')
    },
    getHourMin(date) {
      if (typeof date == 'string') {
        let time = new Date(date)
        var hour = time.getHours()
        hour = String(hour).padStart(2, '0')
        var minute = time.getMinutes()
        minute = String(minute).padStart(2, '0')
        return hour + ':' + minute
      }
    },
    dataAutoDeal(val, type) {
      if (this.isPool) {
        let mating_count = 0,
          mature_count = 0,
          female_loss = 0,
          male_loss = 0
        if (Array.isArray(val)) {
          val.forEach((item) => {
            mating_count += Number(item.mating_count || 0)
            male_loss += Number(item.male_loss || 0)
            mature_count += Number(item.mature_count || 0)
            female_loss += Number(item.female_loss || 0)
          })
        }

        if (type == 'male') {
          this.form.mating_count = mating_count || undefined
          this.form.male_loss = male_loss || undefined
        } else if (type == 'female') {
          this.form.mature_count = mature_count || undefined
          this.form.female_loss = female_loss || undefined
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  padding: 0.5rem;
  .bold-title {
    margin: 0.5rem;
    color: @titleColor;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.pool-handle {
  margin: 0.5rem 1rem;

  /deep/.van-checkbox__label {
    // font-weight: 600;
    // color: @titleColor;
    font-size: 1.1rem;
  }
}

/deep/ .yiyu-box-section {
  margin: 0 !important;
  border-radius: 8px;

  .title {
    .left-border {
      border: none !important;
    }
    .text {
      font-size: 1.1rem;
      color: @titleColor;
      font-weight: 700;
    }
  }
}
</style>
