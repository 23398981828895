<template>
  <van-popup
    v-model="showVisible"
    position="bottom"
    class="popup"
    :close-on-click-overlay="false"
  >
    <div class="header">
      <div class="header-left" @click="cancel" v-if="showCancel">取消</div>
      <div v-else></div>
      <div class="header-title">{{ title }}</div>
      <div class="header-right" @click="confirm" v-if="opts.length && !isSuccessPop">确认</div>
      <div v-else></div>
    </div>

    <div class="content" v-myLoad="loading">
      <slot name="top"></slot>
      <van-radio-group v-model="key" ref="radioGroup" v-if="opts.length">
        <template v-for="(item, index) in opts">
          <van-radio
            v-if="item.show != false"
            :key="index"
            :name="item[keyName]"
            :disabled="isDisabled(item[keyName])"
          >
            <template v-if="!slotName">
              {{ item[valueKey] }}
            </template>
            <slot v-else :name="slotName + index"></slot>
          </van-radio>
        </template>
      </van-radio-group>
      <div class="empty" v-else>
        <slot name="empty"></slot>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'radioPicker',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    opts: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    keyName: {
      type: String,
      default: 'key',
    },
    curKey: {
      type: String,
    },
    disabledKeys: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      require: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    slotName: {
      type: String,
      require: false,
    },
    isSuccessPop: { // 是否仅用于成功后的跳转
      type: Boolean,
      default: true
    }
  },
  mounted() {
    // 如果 当前key 在 disabledKeys 里，应移除
    let index = this.disableds.indexOf(this.key)
    if (index != -1) {
      this.disableds.splice(index, 1)
    }
  },
  watch: {
    show() {
      this.showVisible = this.show
    },
    showVisible(val) {
      this.$emit('update:show', val)
    },
    key() {
      if (this.key && this.isSuccessPop) {
        this.confirm()
      }
    }
  },
  methods: {
    confirm() {
      let obj = this.opts.filter((item) => item[this.keyName] == this.key)[0]
      this.$emit('confirm', { key: this.key, obj: obj })
    },
    cancel() {
      this.$emit('cancel')
    },
    isDisabled(val) {
      if (this.disabledKeys.indexOf(val) !== -1) return true
      else return false
    },
  },
  data() {
    return {
      showVisible: this.show,
      key: this.curKey,
      disableds: this.disabledKeys,
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  height: 40vh;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    padding: 1rem;

    &-left {
      color: @titleColor;
    }

    &-right {
      color: @mainColor;
    }
  }
  .content {
    flex-basis: 100%;
    overflow: auto;
    position: relative;
  }
}
/deep/ .van-radio-group {
  display: flex;
  flex-direction: column;

  .van-radio {
    padding: 10px 16px;
    font-size: 1.1rem;
    font-weight: 500;
    border-bottom: 0.5px solid @lineColor;
  }

  .van-radio[aria-checked='true'] {
    color: @mainColor;
    .van-icon-success {
      color: @mainColor;
    }
  }

  .van-icon {
    background: #fff;
    border: none;
  }
}
</style>
