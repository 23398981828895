<template>
  <div class="yiyu-common-dot-wrap">
    <span class="yiyu-common-dot"></span>
    <span class="yiyu-common-dot"></span>
    <span class="yiyu-common-dot"></span>
  </div>
</template>

<script>
export default {
  name: 'threeDot',
}
</script>

<style lang="less" scoped>
.yiyu-common-dot-wrap {
  .yiyu-common-dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 2px;
    border-radius: 50%;
    background: #909399;
  }
}
</style>
