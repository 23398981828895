export function batchDailyData(daily) {
  return [
    [
      {
        data: daily.female_loss,
        unitText: (daily.female_loss && '条') || '',

        descText: '母虾损耗',
      },
      {
        data: daily.male_loss,
        unitText: (daily.male_loss && '条') || '',
        descText: '公虾损耗',
      },
      {
        data: daily.mature_count,
        unitText: (daily.mature_count && '条') || '',
        descText: '成熟母虾',
      },
      {
        data: daily.mating_count,
        unitText: (daily.mating_count && '条') || '',
        descText: '交配母虾',
      },
    ],
    [
      {
        data: daily.matrix_count,
        unitText: (daily.matrix_count && '条') || '',
        descText: '产卵母虾',
      },
      {
        data: daily.fer_rate,
        unitText: (daily.fer_rate && '%') || '',
        descText: '受精率',
      },
      {
        data: daily.mating_rate,
        unitText: (daily.mating_rate && '%') || '',
        descText: '交配率',
      },
    ],
    [
      {
        data: daily.unit_yield,
        unitText: (daily.mating_rate && '万') || '',
        descText: '明日单产',
      },
      {
        data: daily.e_yield,
        unitText: (daily.mating_rate && '万') || '',
        descText: '明日产量',
      },
      {
        data: daily.real_yield,
        unitText: (daily.mating_rate && '万') || '',
        descText: '今日产量',
      },
      {
        data: (daily.actual_yield?.toFixed(2) * 100) / 100,
        unitText: (daily.actual_yield && '万') || '',
        descText: '今日单产',
      },
    ],
  ]
}
