<template>
  <div class="classify-tag" v-myLoad="loading">
    <div class="classify-tag-item" v-for="(item, index) in list" :key="index">
      <template v-if="single">
        <div class="classify-tag-item-parent">
          {{ item[pLabel] }}({{ item[pChild].length }}个)
        </div>
        <div class="classify-tag-item-children">
          <div
            class="classify-tag-item-child"
            v-for="(item1, index1) in item[pChild]"
            :key="index1"
            :class="{ active: curKey == item1[cKey] }"
            @click="tagClick(item1)"
          >
            {{ item1[cValue] }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="classify-tag-item-parent">
          <div class="label">
            {{ item[pLabel] }}({{ item[pChild].length }}个)
          </div>
          <div
            class="main-c"
            @click="choseAll(item)"
            v-if="item[pChild] && item[pChild].length"
          >
            {{ isAll(item) ? '取消全选' : '全选' }}
          </div>
        </div>
        <div
          class="classify-tag-item-children"
          v-if="item[pChild] && item[pChild].length"
        >
          <div
            class="classify-tag-item-child"
            v-for="(item1, index1) in item[pChild]"
            :key="index1"
            :class="{ active: curKey.includes(item1[cKey]) }"
            @click="tagClick(item1)"
          >
            {{ item1[cValue] }}
          </div>
        </div>
        <van-divider v-else>暂无数据</van-divider>
      </template>
    </div>
  </div>
</template>

<script>
// 该组件用于同一个opts,只是分类进行了展示
export default {
  name: 'classifyTag',
  props: {
    list: {
      // 已分类过的数据
      type: Array,
      default: () => [
        {
          name: '爸爸1',
          children: [
            {
              value: '儿子1',
              key: 'son1',
            },
          ],
        },
        {
          name: '爸爸2',
          children: [
            {
              value: '儿子2',
              key: 'son2',
            },
          ],
        },
      ],
    },
    pLabel: {
      // keyName
      type: String,
      default: 'name',
    },
    pChild: {
      // keyName
      type: String,
      default: 'children',
    },
    cValue: {
      // keyName
      type: String,
      default: 'value',
    },
    cKey: {
      // keyName
      type: String,
      default: 'key',
    },
    single: {
      type: Boolean,
      default: true,
    },
    curKey: {
      // sync
      // required: true,
      type: [String, Number, Array], // 多选的时候才是数组
    },
    curObj: {
      // sync
      require: false, // 只是用来接收数据
    },
    loading: {
      default: false,
    },
  },
  watch: {
    curKey() {
      this.updateObj()
    },
  },
  mounted() {
    this.updateObj()
  },
  methods: {
    updateObj() {
      if (!this.single) {
        let obj = []
        this.list.forEach((item) => {
          item[this.pChild] &&
            item[this.pChild].forEach((item1) => {
              if (this.curKey.includes(item1[this.cKey])) {
                obj.push(item1)
              }
            })
        })
        this.$emit('update:curObj', obj)
      }
    },
    tagClick(item) {
      if (this.single) {
        if (item[this.cKey] == this.curKey) {
          this.$emit('update:curKey', undefined)
          this.$emit('update:curObj', undefined)
        } else {
          this.$emit('update:curKey', item[this.cKey])
          this.$emit('update:curObj', item)
        }
      } else {
        let index = this.curKey.indexOf(item[this.cKey])
        let curKeys = JSON.parse(JSON.stringify(this.curKey))

        if (index != -1) {
          curKeys.splice(index, 1)
        } else {
          curKeys.push(item[this.cKey])
        }
        this.$emit('update:curKey', curKeys)
      }
    },
    choseAll(item) {
      let curKeys = JSON.parse(JSON.stringify(this.curKey))
      if (this.isAll(item)) {
        // 取消全选
        item[this.pChild].forEach((item1) => {
          let index = curKeys.indexOf(item1[this.cKey])
          if (index != -1) {
            curKeys.splice(index, 1)
          }
        })
      } else {
        // 全选
        item[this.pChild].forEach((item1) => {
          let index = curKeys.indexOf(item1[this.cKey])
          if (index == -1) {
            curKeys.push(item1[this.cKey])
          }
        })
      }
      let obj = []
      this.list.forEach((item) => {
        item[this.pChild] &&
          item[this.pChild].forEach((item1) => {
            if (curKeys.includes(item1[this.cKey])) {
              obj.push(item1)
            }
          })
      })
      this.$emit('update:curKey', curKeys)
      this.$emit('update:curObj', obj)
    },
    isAll(item) {
      return item[this.pChild].every((item1) =>
        this.curKey.includes(item1[this.cKey]),
      )
    },
  },
}
</script>

<style lang="less" scoped>
.classify-tag {
  position: relative;
  .classify-tag-item {
    margin: 0.5rem 0;
    .classify-tag-item-parent {
      color: @titleColor;
      font-weight: 600;

      display: flex;
      justify-content: space-between;
    }
    .classify-tag-item-children {
      .classify-tag-item-child {
        border-radius: 8px;
        display: inline-block;
        margin: 0.5rem;
        padding: 0.62rem 1rem;
        white-space: nowrap;
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        background: @bgColor;

        &.active {
          background: rgba(@mainRgb, 0.07);
          color: @mainColor;
        }
      }
    }
  }
}
</style>
