<template>
  <van-popup
    v-model="showVisible"
    @closed="$emit('closed')"
    position="bottom"
    class="popup"
    :close-on-click-overlay="closeOnClickOverlay"
  >
    <div class="header">
      <div class="row-between">
        <div>
          <div class="header-left" @click="cancel" v-if="cancelShow">
            {{ cancelTxt }}
          </div>
        </div>
        <div class="header-center">{{ title }}</div>
        <div class="header-right" @click="confirm">确认</div>
      </div>

      <slot name="header"></slot>
    </div>
    <div class="content">
      <slot name="top"></slot>
      <div class="positive" v-myLoad="loading">
        <classify-tag
          :curKey.sync="key"
          v-bind="$attrs"
          :curObj.sync="pCurObj"
        ></classify-tag>
      </div>
      <slot name="bottom"></slot>
    </div>
  </van-popup>
</template>

<script>
import classifyTag from '@/components/tag/classifyTag.vue'
export default {
  name: 'classifyTagPopup',
  inheritAttrs: false, // 必不可少的代码,避免el-input的属性被添加到根元素div上
  props: {
    pCurKey: {
      // sync
      // required: true,
      type: [String, Number, Array], // 多选的时候才是数组
    },
    title: {
      type: String,
      default: '',
    },
    show: {
      // 需要加 sync 修饰符
      type: Boolean,
      default: false,
    },
    cancelShow: {
      type: Boolean,
      default: true,
    },
    cancelTxt: {
      type: String,
      default: '取消',
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    classifyTag,
  },
  data() {
    return {
      showVisible: this.show,
      key: this.pCurKey,
      pCurObj: undefined,
    }
  },
  watch: {
    show(val) {
      this.showVisible = val
    },
    showVisible(val) {
      this.$emit('update:show', val)
    },
    pCurKey: {
      deep: true,
      handler() {
        this.key = this.pCurKey
      },
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm', { key: this.key, obj: this.pCurObj })
    },
    cancel() {
      this.showVisible = false
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="less" scoped>
.popup {
  display: flex;
  flex-direction: column;
  .header {
    font-size: 1.1rem;
    padding: 1rem;
    padding-bottom: 0;
    text-align: center;

    .row-between {
      display: flex;
      justify-content: space-between;
    }

    &-left {
      color: @textColor;
    }

    &-right {
      color: @mainColor;
    }

    &-center {
      color: @titleColor;
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .content {
    max-height: 50vh;
    overflow-y: auto;
    flex-basis: 100%;
    padding: 1rem;
  }
}
</style>
