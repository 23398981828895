<template>
  <div class="main">
    <yiyu-bar :tab-title="tabTitle"></yiyu-bar>
    <div class="row-between">
      <div class="yiyu-bold-title">{{ form.name || '暂无批次名' }}</div>
      <handle-popver @add="add" @del="del" :actions="actions"></handle-popver>
    </div>
    <yiyu-detail :formOpts="formOpts">
      <template #head>
        <data-field
          class="dailyDataField"
          :dataObj="headList"
          type="grid-box"
        ></data-field>
      </template>
      <template #detail>
        <div class="buttonGroup">
          <van-button
            class="button"
            @click="changeDay('before')"
            type="info"
            :disabled="dayShow == 0"
            size="mini"
            >前一天</van-button
          >
          <van-button
            class="button"
            @click="changeDay('next')"
            type="info"
            :disabled="dayShow == contentList.length - 1"
            size="mini"
            >后一天</van-button
          >
        </div>
        <data-field
          class="dailyDataField"
          :dataObj="detailList"
          type="grid-box"
        >
        </data-field>

        <van-button class="dataEditButton" round plain type="info" @click="edit"
          >编辑</van-button
        >
      </template>
      <template #pool>
        <template v-for="(item, index) in poolList">
          <div :key="item.title" class="listTitle">{{ item.title }}</div>
          <el-table :key="index" stripe :data="item.list" size="mini">
            <el-table-column
              v-for="(value, index1) in poolListTitle"
              :key="index1"
              :prop="value.prop"
              :label="value.label"
              align="center"
            >
            </el-table-column>
          </el-table>
        </template>
        <van-divider v-if="poolList && poolList.length == 0"
          >暂无池数据</van-divider
        >
      </template>
    </yiyu-detail>
  </div>
</template>

<script>
import yiyuBar from '@/components/navbar'
import yiyuDetail from '@/components/detail'
import HandlePopver from '@/components/feedback/handlePopver.vue'
import dataField from '@/components/layout/dataField'
import { Dialog } from 'vant'
import { batchDailyData } from './batchDailyData'

export default {
  name: 'sDailyDetail',
  components: {
    dataField,
    HandlePopver,
    yiyuDetail,
    yiyuBar,
  },
  computed: {
    formOpts() {
      return [
        {
          title: '',
          slot: 'head',
        },
        {
          title: `${
            this.everyDay.record_date &&
            this.everyDay.record_date.substring(5, 10)
          }数据`,
          slot: 'detail',
        },
        {
          title: '池信息',
          slot: 'pool',
        },
      ]
    },
    headList() {
      return [
        [
          {
            data: `公${this.top.male_surplus || 0};母${
              this.top.female_surplus || 0
            }`,
            descText: '当前数量',
          },
          {
            data: this.top.l_date,
            unitText: (this.top.l_date && '天') || '',
            descText: '育苗天数',
          },
          {
            data: this.top.yield_cnt,
            unitText: (this.top.yield_cnt && '万') || '',
            descText: '累计产量',
          },
          {
            data: this.top.avg_yield,
            unitText: (this.top.avg_yield && '万') || '',
            descText: '平均单产',
          },
        ],
      ]
    },
    detailList() {
      return batchDailyData(this.everyDay)
    },
  },
  data() {
    return {
      form: {},
      tabTitle: '每日数据详情',
      actions: [
        {
          text: '编辑',
          fn: 'add',
        },
        {
          text: '删除',
          fn: 'del',
        },
      ],
      ss_key: undefined,
      sr_key: undefined,
      dayShow: 1,
      top: {
        current_count: undefined,
        l_date: undefined, //种虾时间到目前的相隔天数(育苗天数)
        yield_cnt: undefined, //累计产量
      },
      contentList: [],
      everyDay: {},
      content: {},
      poolList: [],
      poolListTitle: [
        {
          label: '池号',
          prop: 'pool_name',
        },
        {
          label: '性别',
          prop: 'sex',
        },
        {
          label: '损耗',
          prop: 'loss',
        },
        {
          label: '成熟',
          prop: 'mature_count',
        },
        {
          label: '交配',
          prop: 'mating_count',
        },
      ],
      postLoading: false,
    }
  },
  created() {
    if (this.$route.query) {
      this.ss_key = this.$route.query.ss_key
      this.sr_key = this.$route.query.sr_key
      this.getData()
    }
  },
  methods: {
    add() {
      this.$toPage(this, 'sDailyEdit', {
        sr_key: this.sr_key,
        ss_key: this.ss_key,
      })
    },
    del() {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$get('/record/delRecord', {
            sr_key: this.sr_key,
            ss_key: this.ss_key,
          }).then((res) => {
            this.postLoading = false
            if (res.code === 10000) {
              this.$toast.success('删除成功！')
              this.$go(-1, this)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    getData() {
      let params = {
        ss_key: this.ss_key,
        sr_key: this.sr_key,
      }
      this.$get('/record/getDetail', params).then((res) => {
        if (res.code == 10000) {
          this.form = res.data
          this.top = res.data.top
          this.contentList = res.data.content || []
          this.content = this.contentList.filter((item, index) => {
            if (item.sr_key === this.sr_key) {
              this.dayShow = index
            }
            return item.sr_key === this.sr_key
          })[0]
          this.everyDay = this.content.everyDay
          this.poolList = this.content.pool_data || []
        }
      })
    },
    changeDay(type) {
      if (type === 'before') {
        this.contentList.some((item, index) => {
          if (this.content.sr_key === item.sr_key) {
            this.$nextTick(() => {
              this.content = this.contentList[index - 1]
              this.everyDay = this.content.everyDay
              this.poolList = this.content.pool_data
            })
            this.dayShow--
            return true
          }
        })
      }
      if (type === 'next') {
        this.contentList.some((item, index) => {
          if (this.content.sr_key === item.sr_key) {
            this.$nextTick(() => {
              this.content = this.contentList[index + 1]
              this.everyDay = this.content.everyDay
              this.poolList = this.content.pool_data
            })
            this.dayShow++
            return true
          }
        })
      }
    },
    edit() {
      this.$toPage(this, 'sDailyEdit', {
        sr_key: this.content.sr_key,
        ss_key: this.ss_key,
      })
    },
  },
}
</script>

<style lang="less" scoped>
.row-between {
  padding: 1rem;
}
.buttonGroup {
  float: right;
  .button {
    margin: 0.5rem;
  }
}
.dailyDataField {
  padding: 1.2rem;
}
.listTitle {
  margin: 1rem 0 0.5rem 1rem;
  font-weight: bold;
}
.dataEditButton {
  margin-left: 77%;
}
</style>
